import { ToastrService } from 'ngx-toastr';
import { Rightid } from './../../interface/RightsId';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from '../../services/shared-data.service';
import { GetRightsService } from '../../services/get-rights.service';
import { EditRoleService } from '../../services/edit-role.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss'],
})
export class EditRoleComponent implements OnInit {
  public EditRole!: FormGroup;

  public displayStyle = 'none';
  editrole_textBox_display = 'none';
  rolename_div_display = 'block';
  Role_value: any = '';
  input_val = this.Role_value;
  default_value = 'Payroll Manager';
  Role: string = '';
  Rights: any;
  Rightid: any;
  rightsIdArr: any = [];
  denialIdArr: any = [];
  item: any;
  checked: boolean = false;
  RoleId: any;
  isloading: boolean = false;
  editRoleOnSubmit: boolean = false;
  editButtonDisable: boolean = false;
  denyString = '';

  constructor(
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private shared: SharedDataService,
    private getRightsApi: GetRightsService,
    private editRoleapi: EditRoleService,
    private Toaster: ToastrService,
    private router: Router,
    private readonly cookieService: CookieService
  ) {}

  ngOnInit() {
    this.EditRole = this.formBuilder.group({
      editRoleText: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(20),
          Validators.pattern('^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$'),
        ],
      ],
    });

    this.Role = this.shared.getROle(); //function get role made in shared service to get role name passed from manage role
    this.Role_value = this.Role;
    this.input_val = this.Role;
    this.RoleId = this.shared.gerRoleId();

    //function made in get rights service to get the all rights  here
    this.getRightsApi.getRights().subscribe((data: any) => {
      this.Rights = data;
      const roleData = {
        RoleId: this.RoleId,
      };

      this.shared
        .getRightsId(JSON.stringify(roleData))
        .subscribe((res: any) => {
          this.Rightid = res;
          if (this.Rightid && this.Rightid.length > 0) {
            for (let i = 0; i < this.Rightid.length; i++) {
              this.rightsIdArr.push(this.Rightid[i].rightsId);
            }
          }

          if (this.Role_value && this.rightsIdArr && this.RoleId) {
            this.cookieService.set('editRole', this.Role_value, 365);
            this.cookieService.set('roleid', this.RoleId, 365);
            this.cookieService.set(
              'rightsIdArray',
              this.rightsIdArr.join(','),
              365
            );
          }
          if (this.Role_value == '') {
            this.Role_value = this.cookieService.get('editRole');
            this.input_val = this.cookieService.get('editRole');
          }
          if (!this.rightsIdArr || this.rightsIdArr.length == 0) {
            this.rightsIdArr = this.cookieService
              .get('rightsIdArray')
              ?.split(',');
          }

          if (this.RoleId == '' || !this.RoleId) {
            this.RoleId = this.cookieService.get('roleid');
          }
          let newRights = this.Rights;
          for (let i = 0; i < newRights.length; i++) {
            for (let j = 0; j < this.rightsIdArr.length; j++) {
              if (newRights[i].id === this.rightsIdArr[j].toString()) {
                newRights[i].checked = true;
              }
            }
          }
        });
    });
  }
  get f() {
    return this.EditRole.controls;
  }

  showTextBox() {
    this.editButtonDisable = true;

    this.displayStyle = 'none';
    this.rolename_div_display = 'none';
    this.editrole_textBox_display = 'block';
  }
  EditRoleError = true;
  editRolePatternError = true;
  update_roleValue() {
    if (!this.EditRole.valid) {
      this.editRoleOnSubmit = true;
    } else if (!(this.rightsIdArr.length > 0) && this.denyString == 'deny') {
      this.toaster.warning('Please grant At-least one capability.');
      this.denyString = '';
    } else if (!(this.rightsIdArr.length > 0)) {
      this.toaster.warning('Please select At-least one Capability.');
    } else if (this.EditRole.valid && this.rightsIdArr.length > 0) {
      this.editButtonDisable = false;
      this.Role_value = this.input_val;
      this.rolename_div_display = 'block';
      this.editrole_textBox_display = 'none';
      this.EditRoleError = true;
      this.editRolePatternError = true;
      const RolenameRightsid = {
        name: this.Role_value,
        rightsId: this.rightsIdArr,
      };

      this.editRoleapi.editRoleSubmit(this.RoleId, RolenameRightsid).subscribe(
        (res: any) => {
          this.isloading = true;
          if (res.status == 'Success') {
            this.Toaster.success(res.message);
            setTimeout(() => {
              this.router.navigate(['/RoleManagement']).then(() => {
                window.location.reload;
              });
            }, 3000);
            this.isloading = false;
          }
        },
        (err: any) => {
          this.isloading = false;
          if (err && err.error && err.error.message) {
            this.Toaster.error(err.error.message);
          } else {
            this.Toaster.error('Role not updated.');
          }
        }
      );
      this.isloading = false;
    }
  }
  changeEditRoleBoolean() {
    this.editRoleOnSubmit = false;
  }

  OpenDropEdit() {
    $('.openDropEdit').slideToggle();
  }

  openEditModel() {
    this.displayStyle = 'block';
  }
  closeEditModel() {
    this.displayStyle = 'none';
  }

  noChanges() {
    this.input_val = this.Role_value;
    this.displayStyle = 'none';
    this.rolename_div_display = 'block';
    this.editrole_textBox_display = 'none';
    this.EditRoleError = true;
    this.editRolePatternError = true;
  }
  getCapabilityValue(ID: any, string: string) {
    ID = parseInt(ID);

    if (string == 'grant' && !this.rightsIdArr.includes(ID)) {
      this.rightsIdArr.push(ID);
    } else if (string == 'deny' && this.rightsIdArr.includes(ID)) {
      this.denyString = string;
      this.denialIdArr.push(ID);

      const index = this.rightsIdArr.indexOf(ID);

      if (index > -1) this.rightsIdArr.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      this.rightsIdArr = this.rightsIdArr;
    }
  }
}
