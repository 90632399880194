import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddRoleComponent } from './components/add-role/add-role.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { EditRoleComponent } from './components/edit-role/edit-role.component';
import { Landing_pageComponent } from './components/landing-page/landing-page.component';
import { LoginComponent } from './components/Login/Login.component';
import { ManageRoleComponent } from './components/manage-role/manage-role.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { LoaderComponent } from './components/loader/loader.component';
import { SharedGuard } from './services/shared.guard';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TwoDigitDecimaNumberDirective } from './helper/twodigitdecimalnumber.directive';
import { environment } from 'src/environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { headerComponent } from './components/header/header.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [
    AppComponent,
    AddRoleComponent,
    ChangeEmailComponent,
    ChangePasswordComponent,
    EditRoleComponent,
    headerComponent,
    Landing_pageComponent,
    LoginComponent,
    ManageRoleComponent,
    LoaderComponent,
    TwoDigitDecimaNumberDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    OrderModule,
    ToastrModule.forRoot({
      timeOut: environment.toastertime,
    }),
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule,
    NgbModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    SharedGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
