import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Forgetpassword } from '../interface/forgetpassword';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root'
})
export class ForgetpasswordService {

  constructor(private http: HttpClient, private commonSerivce: CommonService) { }


  forgetPassword(data: any) {
    return this.commonSerivce.wihtoutTokenSave(environment.endPointAPI + commonClass.Authenticate + "/forgot-password", data)
  }
}
