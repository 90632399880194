<div class="wrapper">




  <div class="loginsec_bg">
    <div class="loginsquarbox_bg">
    <div class="login_box">
      <form class="logpage_frm" action="Manage-Timesheet.html" [formGroup]="loginForm" (ngSubmit)="btnlogin()">
        <div class="sqqlogo">
          <img src="../../../assets/images/SixQuotumQuixLogos.png" alt="Six Quotum Quix"/></div>
      <div class="logo trslogo">
          <a><img src="../../../assets/images/UMS_White_LOGO.svg" alt="logo"></a>
      </div>


        <h2>Login</h2>

        <div class="lfrm_control lusr">
          <label class="lfrm_lbl">User Name<sup>*</sup></label>
          <input formControlName="username" class="input100" type="text" name="username"
            placeholder="Type your username">

          <div *ngIf="submitted && f['username'].errors" class="text-danger">
            <div *ngIf="f['username'].errors['required']">Username is required.</div>
            <div class="lfrm-fcusline"></div>
          </div>
        </div>
        <div class="lfrm_control lpass">
          <label class="lfrm_lbl">Password<sup>*</sup></label>
          <input formControlName="password" class="input100" type="password" name="password"
            placeholder="Type your password">

          <div *ngIf="submitted && f['password'].errors" class="text-danger">
            <div *ngIf="f['password'].errors['required']">Password is required.</div>
            <div class="lfrm-fcusline"></div>
          </div>
        </div>



        <div class="remdiv"><input type="checkbox" formControlName="lsRememberMe" />Remember me next time</div>

        <div *ngIf="isLoading">
          <app-loader></app-loader>
        </div>

        <div class="lfrm-actrow">
          <button class="lfrm-btn bg_blue">login</button>
          <a (click)="openModal()" class="lfrm-pasurl" id="fgotlink">Forgot Password?</a>
        </div>


      </form>
      </div>
    </div>
  </div>
</div>

<div id="fgotmodal" class="srem_modbox" [ngStyle]="{'display':displayStyle}">
  <div class="sremod_content">
    <div class="sremod_header">
      <span class="close" (click)="close()">×</span>
      <h3>Forgot Password</h3>
    </div>
    <div class="sremod_body">
      <form class="sremod_frm" action="Manage-Timesheet.html" [formGroup]="ForgetPasswordForm">

        <div class="srfc_control">
          <label class="srfc_lbl">Enter UserName OR registered Email to receive system generated
            Password<sup>*</sup></label>

          <input formControlName="UserName" type="text" name="UserName" class="form-control"
            placeholder="Username / Registered Email">


          <div *ngIf="forgetpasswordsubmitted && forgetPasswordcontrols['UserName'].errors" class="text-danger">
            <div *ngIf="forgetPasswordcontrols['UserName'].errors['required']">Username or Registered Email is required.
            </div>
            <div class="lfrm-fcusline"></div>
          </div>
        </div>

        <div class="srfc-actrow">
          <button class="def_btn bg_blue" (click)="btnforgetPassword()">Submit</button>
          <button (click)="close()" class="def_btn bg_lgray">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>