import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root'
})
export class AddRoleService {

  constructor(private commonService: CommonService) { }
  // called from add-role  component with data=>role name and rights id
  addRole(data: any) {
    return this.commonService.wihtoutTokenSave(environment.endPointAPI + commonClass.Role + "/createRole", data);
  }
}
