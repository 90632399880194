export const environment = {
    production: true,
    // endPointAPI: "https://localhost:7138/api/",
    toastertime: 5000,
    endPointAPI: "https://trsapi.azurewebsites.net/api/",
    umsURL: "http://ums.knacktek.com:8091",
    cmsURL: "http://cms.knacktek.com:8092",
    trsURL: "http://trs.knacktek.com:8093",
    version: '0.0.0',
    domain: "knacktek.com",
    sixconsultingcorpdomain: "sixconsultingcorp.com",
    quotumtechdomain: "quotumtech.com",
    CryptoTSkey: 'MTIzNDU2Nzg5MEFCQ0RFRkdISUpLTE1O',
    CryptoTSIV: 'MTIzNDU2Nzg=',
    CryptoJSkey: '1203199320052021',
    CryptoJSIV: '1203199320052021',
};
