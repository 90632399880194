<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="wrapper">

  <div class="ae_innbox">
    <div class="inn_container">
      <div class="umin_sec">
        <div class="umtp_act">

          <div class="umacts">



            <div ngbDropdown class="head_litem">
              <button (click)="openDropDown2()" id="dropdownBasic2" ngbDropdownToggle class="toggle_menu"
                aria-haspopup="true" aria-expanded="true">
                <i class="far fa-calendar-alt"></i>Available Actions</button>
              <div ngbDropdownMenu class="dropOpen2 head_dropdown">
                <button class="dropdownbutton" (click)="activateUsers()" ngbDropdownItem>Activate</button>
                <button class="dropdownbutton" (click)="deactivateUsers()" ngbDropdownItem>Deactivate</button>
                <button class="dropdownbutton" (click)="lockUsers()" ngbDropdownItem>Lock</button>
                <button class="dropdownbutton" (click)="unlockUsers()" ngbDropdownItem>Unlock</button>
              </div>
            </div>

            <div ngbDropdown class="head_litem">
              <button aria-haspopup="true" (click)="openDropDown()" aria-expanded="true" class="availableOption_toggle"
                id="dropdownBasic1" ngbDropdownToggle>
                <i class="far fa-calendar-alt"></i>Available Options</button>
              <div ngbDropdownMenu class="dropOpen head_dropdown">
                <button class="dropdownbutton" (click)="linkManagerUser()" ngbDropdownItem>Manage User</button>
                <button class="dropdownbutton" (click)="openModal()" ngbDropdownItem>Add New User</button>

              </div>
            </div>



            <div class="head_litem">

              <a (click)="btndownload()" class="def_btn bg_blue" title="Download">Download <i
                  class="fa-solid fa-download"></i></a>
            </div>

          </div>
        </div>

        <div class="umlp_srsec">
          <div class="faq_item">
            <div class="faq_head" title="Expand" (click)="btnsearch()">
              <h4>Search</h4>
            </div>
            <div class="faq_desc">
              <form [formGroup]="searchForm">
                <div class="umlp_srrow">
                  <div class="umlp_sritm">
                    <input type="text" class="form-control" name="First Name" placeholder="First Name"
                      formControlName="firstName" />
                  </div>

                  <div class="umlp_sritm">
                    <input type="text" class="form-control" name="Last Name" placeholder="Last Name"
                      formControlName="lastName" />
                  </div>
                  <div class="umlp_sritm">
                    <input type="text" class="form-control" name="Username" placeholder="User Name"
                      formControlName="userName" />
                  </div>
                  <div class="umlp_sritm">
                    <input type="text" class="form-control" name="Email" placeholder="Email" formControlName="email" />
                  </div>

                  <div class="umlp_sritm">
                    <select class="form-control" formControlName="role">
                      <option value="">Select Role</option>
                      <option *ngFor="let roles of bindRoles" [value]="roles.roleName">
                        {{roles.roleName}}
                      </option>

                    </select>
                  </div>
                  <div class="umlp_sritm">
                    <select class="form-control" formControlName="status">
                      <option value="">Status</option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>
                  <div class="umlp_sritm">
                    <select class="form-control" formControlName="lockStatus">
                      <option value="">Lock Status</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>
                <div class="umlp_frbtn">
                  <button (click)="btnSubmit()" class="def_btn bg_blue">Search</button>
                  <button (click)="clearSearchForm()" class="def_btn bg_lgray">Clear</button>
                </div>
              </form>
            </div>
          </div>


        </div>


        <div class="ums_mbtbl">
          <div class="tbl-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)">
                  </th>
                  <th (click)="sortfirstName('FirstName')">First Name<a (click)="filterByName()" title="Order">
                      <i *ngIf="isnamesort" class="fa-solid fa-sort"></i></a></th>

                  <th (click)="sortfirstName('LastName')">Last Name<a (click)="filterByName()" title="Order"><i
                        *ngIf="isLastNamesort" class="fa-solid fa-sort"></i></a></th>
                  <th (click)="sortfirstName('UserName')">User Name<a (click)="filterByName()" title="Order"><i
                        *ngIf="isuserNamesort" class="fa-solid fa-sort"></i></a></th>
                  <th (click)="sortfirstName('Email')">Email<a (click)="filterByName()" title="Order"><i
                        *ngIf="isemailsort" class="fa-solid fa-sort"></i></a></th>

                  <th (click)="sortfirstName('Roles')">Role<a (click)="filterByName()" title="Order"><i
                        *ngIf="isrolesort" class="fa-solid fa-sort"></i></a></th>
                  <th (click)="sortfirstName('Active')">Status<a (click)="filterByName()" title="Order"><i
                        *ngIf="isactivesort" class="fa-solid fa-sort"></i></a></th>
                  <th (click)="sortfirstName('IsLockedOut')">Lock Status<a (click)="filterByName()" title="Order"><i
                        *ngIf="islockedOutsort" class="fa-solid fa-sort"></i></a></th>
                  <th>Reset Password</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let i=index; let data  of userDtails   | paginate : {itemsPerPage:itemsPage,currentPage:page, totalItems:userData  }">
                  <td><input type="checkbox" value="{{data.userName}}" [(ngModel)]="userDtails[i].checked"
                      (click)="checkBoxChecked(data.userName)" (click)="checkBoxActive(data)">
                  </td>
                  <td>{{data.firstName}}</td>

                  <td>{{data.lastName}}</td>
                  <td>{{data.userName}}</td>
                  <td>{{data.email}}</td>

                  <!-- <td>{{data.roles.join(', ')}}</td> -->
                  <td>{{data.roles}}</td>

                  <td>{{data.active ? 'Active' :'Inactive'}}</td>

                  <td>{{data.isLockedOut ? 'Yes' : 'No'}}</td>
                  <td><a (click)="showResetPassConfirmation(data)" title="Reset Password"><i
                        class="fas fa-solid fa-arrows-rotate"></i></a></td>
                  <td><a (click)="openEditUser(data)" #openEditUserForm id="euser_btn" title="Edit"><i
                        class="fas fa-solid fa-pen-to-square"></i></a></td>
                  <td><a (click)="showDeleteUserConfirmation(data.userName)" title="Delete"><i
                        class="fas fa-solid fa-trash-can"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="isrecordnotfound" class="norecordfound">
            No records available
          </div>

          <div class="mtsp_btmpart">
            <div class="mtsp_pgebox">
              <div class="mtsp_psize">
                <label>Page Size</label>
                <select class="form-control" (change)="pageSelect()" [(ngModel)]="selectpage">
                  <option *ngFor="let selectedPage of selectedPage" [ngValue]="selectedPage">{{selectedPage}}</option>
                </select>
              </div>
              <div class="mtsp_recbox">
                <label>Records:</label>
                <p><span>{{pageStartFrom}}{{pageEndAt}}</span>of {{userData}}&nbsp;&nbsp;&nbsp; Page<span>{{page}} of
                    {{floorItempage}}</span>
              </div>
            </div>

            <div class="pagination">
              <li>
                <a [ngClass]="{'anchor-disable': isFirstPage()}" (click)="firstPage()"><<</a>
              </li>

              <pagination-controls [maxSize]="sizeOfPagination" [responsive]="responsive"
                [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel"
                (pageChange)="handlePageChange($event)">
              </pagination-controls>

              <li>
                <a [ngClass]="{'anchor-disable': isLastPage()}" (click)="lastPage()">>></a>
              </li>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>


</div>






<div id="auser_box" class="srem_modbox" #menuModal [ngStyle]="{'display':displayStyle}">
  <div class="sremod_content">
    <div class="sremod_header">
      <span (click)="close()" class="close" onclick="document.getElementById('sremodal').style.display='none';">×</span>
      <h3>Add New User</h3>
    </div>
    <div class="sremod_body">
      <form class="sremod_frm" action="" [formGroup]="AddUserForm">
        <div class="srfc_control">
          <label class="srfc_lbl">Username<sup>*</sup></label>
          <input type="text" name="user" class="form-control" placeholder="Username" formControlName="username">

          <div *ngIf="submitted && valid['username'].errors" class="text-danger">
            <div *ngIf="valid['username'].errors['required']">Username is required.</div>
            <div
              *ngIf="((valid['username'].errors['minlength'] || valid['username'].errors['maxlength'] ) && (!valid['username'].errors['pattern']))">
              The
              Username must be minimum 4 and maximum 40 characters long.</div>

            <div *ngIf="valid['username'].errors['pattern']">Special character are not valid & Username must start with
              alphabet only.</div>
          </div>



        </div>


        <label class="agpass_lbl"><input type="checkbox" checked="item.check" disabled="true">
          <p>Auto Generate Password</p>
        </label>
        <div class="srfc_control">
          <label class="srfc_lbl">First Name<sup>*</sup></label>
          <input type="text" name="fname" class="form-control" placeholder="First Name" formControlName="firstName">

          <div *ngIf="submitted && valid['firstName'].errors" class="text-danger">
            <div *ngIf="valid['firstName'].errors['required'] &&
        (!valid['firstName'].errors['maxlength'] && !valid['firstName'].errors['pattern'])">First Name is required.
            </div>
            <div *ngIf="valid['firstName'].errors['pattern'] && (!valid['firstName'].errors['maxlength'] &&
        !valid['firstName'].errors['required'])">White spaces and special characters are not allowed in First Name.
            </div>
            <div
              *ngIf="valid['firstName'].errors['maxlength'] || (!valid['firstName'].errors['pattern'] && !valid['firstName'].errors['required'])">
              The First Name must be maximum 20 characters long.</div>
          </div>



        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Middle Name</label>
          <input type="text" name="fname" class="form-control" placeholder="Middle Name" formControlName="middleName">



          <div *ngIf="submitted && valid['middleName'].errors" class="text-danger">
            <div *ngIf="valid['middleName'].errors['pattern'] && !valid['middleName'].errors['maxlength']">White spaces
              and special characters are not allowed in
              Middle Name</div>
            <div *ngIf="valid['middleName'].errors['maxlength'] || !valid['middleName'].errors['pattern']">The Middle
              Name must be maximum 20 characters long..
            </div>
          </div>


        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Last Name<sup>*</sup></label>
          <input type="text" name="lname" class="form-control" placeholder="Last Name" formControlName="lastName">

          <div *ngIf="submitted && valid['lastName'].errors" class="text-danger">
            <div *ngIf="valid['lastName'].errors['required'] &&
        (!valid['lastName'].errors['maxlength'] && !valid['lastName'].errors['pattern'])">Last Name is required.</div>
            <div *ngIf="valid['lastName'].errors['pattern'] && (!valid['lastName'].errors['maxlength'] &&
        !valid['lastName'].errors['required'])">White spaces and special characters are not allowed in Last Name.</div>
            <div
              *ngIf="valid['lastName'].errors['maxlength'] || (!valid['lastName'].errors['pattern'] && !valid['lastName'].errors['required'])">
              The Last Name must be maximum 20 characters long.</div>
          </div>





        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Email<sup>*</sup></label>
          <input type="email" name="mail" class="form-control" placeholder="Email" formControlName="email"
            pattern="^([a-zA-Z0-9_\.]*[a-zA-Z0-9])@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9]+\.)+))([a-zA-Z]{2,4})(\]?)$">

          <div *ngIf="submitted && valid['email'].errors" class="text-danger">
            <div *ngIf="valid['email'].errors['required']">Email is required.</div>
            <div *ngIf="valid['email'].errors['pattern']">E-mail address you entered is in incorrect format. Please
              enter
              proper E-mail.</div>
            <div *ngIf="valid['email'].errors['maxlength']">Maximum length is 255 characters.</div>
          </div>


        </div>

        <div class="srfc_control">
          <label class="srfc_lbl">Title<sup>*</sup></label>
          <input type="text" name="title" class="form-control" placeholder="Title" formControlName="title">

          <div *ngIf="submitted && valid['title'].errors" class="text-danger">
            <div *ngIf="valid['title'].errors['required']">Title is required.</div>
            <div *ngIf="valid['title'].errors['maxlength']">Maximum length is 100 characters.</div>
          </div>


        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Phone<sup>*</sup></label>

          <input type="textbox" name="phone" maxlength="15" class="form-control" formControlName="phone"
            appTwoDigitDecimaNumber>
          <div *ngIf="submitted && valid['phone'].errors" class="text-danger">
            <div *ngIf="valid['phone'].errors['required']">Phone is required.</div>
            <div *ngIf="valid['phone'].errors['maxlength']">Maximum length is 15 characters.</div>
            <div *ngIf="valid['phone'].errors['pattern']">Please enter valid phone number.</div>
          </div>

        </div>


        <div class="srfc_control">
          <label class="srfc_lbl">Payroll<sup>*</sup></label>
          <select [(ngModel)]="selectpayroll" class="form-control" formControlName="payroll">
            <option value="">Select</option>
            <option *ngFor="let payroll of Payroll" [ngValue]="payroll">{{payroll}}</option>
          </select>

          <div *ngIf="submitted && valid['payroll'].errors" class="text-danger">
            <div *ngIf="valid['payroll'].errors['required']">Payroll is required.</div>
          </div>



        </div>


        <div hidden>
          <select class="form-control" formControlName="company">
          </select>
        </div>




        <div class="srfc_control">
          <label class="srfc_lbl">Comment</label>
          <textarea class="form-control" rows="5" cols="20" formControlName="comment"></textarea>

          <div *ngIf="submitted && valid['comment'].errors" class="text-danger">
            <div *ngIf="valid['comment'].errors['maxlength']">Maximum length is 50 characters.</div>
          </div>


        </div>


        <div class="srfc-actrow">
          <button (click)="btnAddUser()" class="def_btn bg_blue">Sign Up</button>
          <button (click)="close()" class="def_btn bg_lgray">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>



<div id="euser_box" class="srem_modbox" #menuEditModal [ngStyle]="{'display':displayStyleedit}">
  <div class="sremod_content">
    <div class="sremod_header">
      <span class="close" (click)="closeEdit()">×</span>
      <h3>Edit User</h3>
    </div>
    <div class="sremod_body">
      <form class="sremod_frm" [formGroup]="editUserForm">
        <div class="srfc_control">
          <label class="srfc_lbl">Username<sup>*</sup></label>
          <input style="cursor: not-allowed" readonly="readonly" type="text" name="user" class="form-control"
            placeholder="Username" formControlName="user">

          <div *ngIf="editsubmitted && validedit['user'].errors" class="text-danger">
            <div *ngIf="validedit['user'].errors['required']">Username is required.</div>
            <div
              *ngIf="((validedit['user'].errors['minlength'] || validedit['user'].errors['maxlength'] ) && (!validedit['user'].errors['pattern']))">
              The
              Username must be minimum 4 and maximum 40 characters long.</div>

            <div *ngIf="validedit['user'].errors['pattern']">Special character are not valid & Username must start with
              alphabet only. </div>
          </div>


        </div>

        <div class="srfc_control">
          <label class="srfc_lbl">First Name<sup>*</sup></label>
          <input type="text" name="fname" class="form-control" placeholder="First Name" formControlName="firstName">

          <div *ngIf="editsubmitted  && validedit['firstName'].errors" class="text-danger">
            <div *ngIf="validedit['firstName'].errors['required'] &&
        (!validedit['firstName'].errors['maxlength'] && !validedit['firstName'].errors['pattern'])">First Name is
              required.</div>
            <div *ngIf="validedit['firstName'].errors['pattern'] && (!validedit['firstName'].errors['maxlength'] &&
        !validedit['firstName'].errors['required'])">White spaces and special characters are not allowed in First Name.
            </div>
            <div
              *ngIf="validedit['firstName'].errors['maxlength'] || (!validedit['firstName'].errors['pattern'] && !validedit['firstName'].errors['required'])">
              The First Name must be maximum 20 characters long.</div>
          </div>



        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Middle Name</label>
          <input type="text" name="fname" class="form-control" placeholder="Middle Name" formControlName="middleName">

          <div *ngIf="editsubmitted && validedit['middleName'].errors" class="text-danger">
            <div *ngIf="validedit['middleName'].errors['pattern'] && !validedit['middleName'].errors['maxlength']">White
              spaces
              and special characters are not allowed in
              Middle Name</div>
            <div *ngIf="validedit['middleName'].errors['maxlength'] || !validedit['middleName'].errors['pattern']">The
              Middle
              Name must be maximum 20 characters long..
            </div>
          </div>



        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Last Name<sup>*</sup></label>
          <input type="text" name="lname" class="form-control" placeholder="Last Name" formControlName="lastName">


          <div *ngIf="editsubmitted && validedit['lastName'].errors" class="text-danger">
            <div *ngIf="validedit['lastName'].errors['required'] &&
        (!validedit['lastName'].errors['maxlength'] && !validedit['lastName'].errors['pattern'])">Last Name is
              required.
            </div>
            <div *ngIf="validedit['lastName'].errors['pattern'] && (!validedit['lastName'].errors['maxlength'] &&
        !validedit['lastName'].errors['required'])">White spaces and special characters are not allowed in Last Name.
            </div>
            <div
              *ngIf="validedit['lastName'].errors['maxlength'] || (!validedit['lastName'].errors['pattern'] && !validedit['lastName'].errors['required'])">
              The Last Name must be maximum 20 characters long.</div>
          </div>



        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Email<sup>*</sup></label>
          <input type="email" name="mail" class="form-control" placeholder="Email" formControlName="email"
            pattern="^([a-zA-Z0-9_\.]*[a-zA-Z0-9])@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9]+\.)+))([a-zA-Z]{2,4})(\]?)$">


          <div *ngIf="editsubmitted && validedit['email'].errors" class="text-danger">
            <div *ngIf="validedit['email'].errors['required']">Email is required.</div>
            <div *ngIf="validedit['email'].errors['pattern']">E-mail address you entered is in incorrect format.Please
              enter
              proper E-mail.</div>
            <div *ngIf="validedit['email'].errors['maxlength']">Maximum length is 255 characters.</div>
          </div>




        </div>
        <!--
        UMS change  -->
        <div class="srfc_control">
          <label class="srfc_lbl">Role</label>

          <select [(ngModel)]="selectedRolevalue" class=" form-control" formControlName="role">
            <option value="" selected>Select the role</option>
            <option *ngFor="let role of bindRoles" [ngValue]="role.roleName">{{role.roleName}}</option>
          </select>
          <div *ngIf="editsubmitted && validedit['role'].errors" class="text-danger">
            <div *ngIf="validedit['role'].errors['required']">Role is required.</div>
          </div>
        </div>




        <div class="srfc_control">
          <label class="srfc_lbl">Title<sup>*</sup></label>
          <input type="text" name="title" class="form-control" placeholder="Title" formControlName="title">

          <div *ngIf="editsubmitted && validedit['title'].errors" class="text-danger">
            <div *ngIf="validedit['title'].errors['required']">Title is required.</div>
            <div *ngIf="validedit['title'].errors['maxlength']">Maximum length is 100 characters.</div>
          </div>


        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Phone<sup>*</sup></label>


          <input type="textbox" name="phone" maxlength="15" class="form-control" formControlName="phone"
            appTwoDigitDecimaNumber>

          <div *ngIf="editsubmitted && validedit['phone'].errors" class="text-danger">
            <div *ngIf="validedit['phone'].errors['required']">Phone is required.</div>
            <div *ngIf="validedit['phone'].errors['maxlength']">Maximum length is 15 characters.</div>
            <div *ngIf="validedit['phone'].errors['pattern']">Please enter valid phone number.</div>
          </div>

        </div>


        <input type="hidden" formControlName="userId">


        <div class="srfc_control">
          <label class="srfc_lbl">Payroll<sup>*</sup></label>
          <select [(ngModel)]="selectpayroll" class="form-control" formControlName="payroll">

            <option *ngFor="let payroll of Payroll" [ngValue]="payroll">{{payroll}}</option>
          </select>

          <div *ngIf="editsubmitted &&  validedit['payroll'].errors" class="text-danger">
            <div *ngIf=" validedit['payroll'].errors['required']">Payroll is required.</div>
          </div>

        </div>


        <div hidden>
          <select class="form-control" formControlName="company">
          </select>
        </div>



        <div class="srfc_control">
          <label class="srfc_lbl">Comment</label>
          <textarea class="form-control" rows="5" cols="20" formControlName="comment"></textarea>

          <div *ngIf="editsubmitted && validedit['comment'].errors" class="text-danger">
            <div *ngIf="validedit['comment'].errors['maxlength']">Maximum length is 50 characters</div>
          </div>

        </div>


        <div class="srfc-actrow">
          <button (click)="btnEditUser()" class="def_btn bg_blue">Update</button>
          <button (click)="closeEdit()" class="def_btn bg_lgray">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<p id="resmsg"></p>

<div id="eroleModal" class="srem_modbox" [ngStyle]="{'display':resetPassDisplay}">
  <div class="sremod_content">
    <div class="sremod_header">
      <span (click)="noChanges()" class="close">×</span>
      <h3>Reset Password</h3>
    </div>
    <div class="sremod_body">
      <form class="sremod_frm" action="">
        <div class="srfc_control ernapbox">
          <label class="srfc_lbl">Are you sure you want to Reset Password?</label>
          <div class="umrt_btn mtop20">
            <a class="def_btn bg_blue" (click)="resetPassword()">YES</a>
            <a class="def_btn bg_blue" (click)="noChanges()">NO</a>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>


<div id="eroleModal" class="srem_modbox" [ngStyle]="{'display':deleteUserDisplay}">
  <div class="sremod_content">
    <div class="sremod_header">
      <span (click)="noChanges()" class="close">×</span>
      <h3>Delete User</h3>
    </div>
    <div class="sremod_body">
      <form class="sremod_frm" action="">
        <div class="srfc_control ernapbox">
          <label class="srfc_lbl">Are you sure you want to delete this user?</label>
          <div class="umrt_btn mtop20">
            <a class="def_btn bg_blue" (click)="deleteUser()">YES</a>
            <a class="def_btn bg_blue" (click)="noChanges()">NO</a>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>