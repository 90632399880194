<div class="wrapper">

  <div class="ae_innbox">
    <div class="inn_container">
      <div class="umin_sec">



        <div class="umrt_box aroll">
          <label class="srch_mlbl">Role name<sup>*</sup></label>
          <div class="umrt_itm">
            <div class="umrt_input">
              <form [formGroup]="frmAddRole">
                <input type="text" class="form-control" placeholder="Role name" formControlName="name"
                  (ngModelChange)="changeisaddRoleBoolean()" />

                <div *ngIf="isaddRole && f['name'].errors" class="text-danger">
                  <div *ngIf="((f['name'].errors['minlength'] ||
                  f['name'].errors['maxlength']) && (!f['name'].errors['pattern'])) ">Required Minimum 4 character and
                    Maximum 20.</div>
                  <div *ngIf="(f['name'].errors['pattern']) "> Special characters are not allowed.</div>

                  <div *ngIf="f['name'].errors['required'] ">Role name is required.</div>
                  <div class="lfrm-fcusline"></div>
                </div>
              </form>

            </div>
            <div class="umrt_btn">
              <a class="def_btn bg_blue" (click)="btnAddRole()">Add</a>
              <a class="def_btn bg_blue" routerLink="/RoleManagement">Cancel</a>
            </div>
          </div>
        </div>


        <div class="ums_mbtbl umrtbl">
          <div class="tbl-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Capability</th>
                  <th>Grant</th>
                  <th>Deny</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let capability of Rights">
                  <td>{{capability.name}}</td>
                  <td><a (click)="getCapabilityValue(capability.id,'grant')" title="Grant"><input type="radio"
                        name={{capability.name}} value="Grant"></a></td>
                  <td><a (click)="getCapabilityValue(capability.id,'deny')" title="Deny"><input type="radio"
                        name={{capability.name}} value="Deny"></a></td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
<div *ngIf="addRoleLoader">
  <app-loader></app-loader>
</div>

<div id="auser_box" class="srem_modbox">
  <div class="sremod_content">
    <div class="sremod_header">
      <span class="close" onclick="document.getElementById('sremodal').style.display='none';">×</span>
      <h3>Add New User</h3>
    </div>
    <div class="sremod_body">
      <form class="sremod_frm" action="Manage-Timesheet.html">
        <div class="srfc_control">
          <label class="srfc_lbl">User</label>
          <input type="text" name="user" class="form-control" placeholder="User">
        </div>
        <label class="agpass_lbl"><input type="checkbox">
          <p>Auto Generate Password</p>
        </label>
        <div class="srfc_control">
          <label class="srfc_lbl">First Name</label>
          <input type="text" name="fname" class="form-control" placeholder="First Name">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Middle Name</label>
          <input type="text" name="fname" class="form-control" placeholder="Middle Name">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">First Name</label>
          <input type="text" name="fname" class="form-control" placeholder="First Name">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Email</label>
          <input type="text" name="mail" class="form-control" placeholder="Email">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Role</label>
          <select class="form-control">
            <option>Select Role</option>
            <option>Role 1</option>
            <option>Role 2</option>
            <option>Role 3</option>
          </select>
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Title</label>
          <input type="text" name="title" class="form-control" placeholder="Title">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Phone</label>
          <input type="number" name="phone" class="form-control" placeholder="Phone">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Employee Id</label>
          <input type="email" name="empid" class="form-control" placeholder="Employee Id">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Email</label>
          <input type="email" name="mail" class="form-control" placeholder="Email">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Payroll</label>
          <select class="form-control">
            <option>Select</option>
            <option>Payroll 1</option>
            <option>Payroll 2</option>
            <option>Payroll 3</option>
          </select>
        </div>

        <div class="srfc_control">
          <label class="srfc_lbl">Company Name</label>
          <select class="form-control">
            <option>Select Company</option>
            <option>Company 1</option>
            <option>Company 2</option>
            <option>Company 3</option>
          </select>
        </div>

        <div class="srfc_control">
          <label class="srfc_lbl">Comment</label>
          <textarea class="form-control" rows="5" cols="20"></textarea>
        </div>


        <div class="srfc-actrow">
          <button class="def_btn bg_blue">Sign Up</button>
          <button class="def_btn bg_lgray">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>


