<div class="wrapper">
  <div class="loginsec_bg">
    <div class="loginsquarbox_bg">
      <div class="login_box">
        <form class="logpage_frm" [formGroup]="changeEmail">
          <div class="sqqlogo">
            <img src="../../../assets/images/SixQuotumQuixLogos.png" alt="Six Quotum Quix" />
          </div>
          <div class="logo trslogo">
            <a href="/ManageUser"><img src="../../../assets/images/UMS_White_LOGO.svg" alt="logo"></a>
          </div>


          <h2>Change Email</h2>

          <div class="lfrm_control lmail">
            <label class="lfrm_lbl">Current Email<sup>*</sup></label>
            <input formControlName="currentemail" class="input100" type="email" name="email" placeholder="Current Email"
              pattern="^([a-zA-Z0-9_\.]*[a-zA-Z0-9])@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9]+\.)+))([a-zA-Z]{2,4})(\]?)$">

            <div *ngIf="issubmitChangeEmail && f['currentemail'].errors" class="text-danger">
              <div *ngIf="f['currentemail'].errors['required']">Current Email is required.</div>
              <div *ngIf="f['currentemail'].errors['pattern']">Email address you entered is in incorrect format. Please
                enter proper Email.</div>
              <div class="lfrm-fcusline"></div>
            </div>
          </div>
          <div class="lfrm_control lmail">
            <label class="lfrm_lbl">New Email<sup>*</sup></label>
            <input (blur)="newemailblur()" formControlName="newemail" class="input100" type="email" name="email"
              placeholder="New Email"
              pattern="^([a-zA-Z0-9_\.]*[a-zA-Z0-9])@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9]+\.)+))([a-zA-Z]{2,4})(\]?)$">

            <div *ngIf="issubmitChangeEmail && f['newemail'].errors" class="text-danger">
              <div *ngIf="f['newemail'].errors['required']">New Email is required.</div>
              <div *ngIf="f['newemail'].errors['pattern'] &&
            samenew_email">Email address you entered is in incorrect format. Please enter proper Email.</div>
              <div class="lfrm-fcusline"></div>
            </div>



            <span class="text-danger" *ngIf="  !samenew_email && issubmitChangeEmail">New
              Email cant be same as current Email.</span>
            <div class="lfrm-fcusline"></div>
          </div>

          <div class="lfrm_control lmail">
            <label class="lfrm_lbl">Confirm New Email<sup>*</sup></label>
            <input formControlName="confirmemail" class="input100" type="email" name="email"
              placeholder="Confirm New Email"
              pattern="^([a-zA-Z0-9_\.]*[a-zA-Z0-9])@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9]+\.)+))([a-zA-Z]{2,4})(\]?)$">

            <div *ngIf="issubmitChangeEmail && f['confirmemail'].errors" class="text-danger">
              <div *ngIf="f['confirmemail'].errors['required']">Confirm Email is required.</div>
              <div *ngIf="f['confirmemail'].errors['pattern']">Email address you entered is in incorrect format. Please
                enter proper Email.</div>
              <div *ngIf="f['confirmemail'].errors['mustMatch']">Confirm email does not match.</div>
              <div class="lfrm-fcusline"></div>
            </div>


          </div>

          <div class="cpas_btns cusrow">
            <button (click)="submitEmail()" class="def_btn bg_blue">Change Email</button>
            <button routerLink="/ManageUser" class="def_btn bg_lgray">Cancel</button>
          </div>


        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>