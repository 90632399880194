import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Changeemail } from 'src/app/interface/changeemail';
import { HttpClient } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token.service';
import { ChangeemailService } from 'src/app/services/changeemail.service';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/helper/must-match.validator';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-mail_password',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent implements OnInit {
  public changeEmail!: FormGroup;
  samenew_email = true;
  changeEmailObj!: Changeemail;
  serverError: boolean | undefined;
  public localuser: any;
  isLoading = false;
  issubmitChangeEmail: boolean = false;

  constructor(
    private toaster: ToastrService,
    private tokenStorage: TokenStorageService,
    private api: ChangeemailService,
    private formbuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private readonly cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.localuser = this.cookieService.get('username');
    this.changeEmail = this.formbuilder.group(
      {
        currentemail: ['', [Validators.required]],
        newemail: ['', [Validators.required]],
        confirmemail: ['', [Validators.required]],
      },
      {
        validator: MustMatch('newemail', 'confirmemail'),
      }
    );
  }

  get f(): any {
    return this.changeEmail.controls;
  }

  /* for validaing change email form details and passing data to change Email function in change email service. */
  submitEmail() {
    this.issubmitChangeEmail = true;

    if (
      this.changeEmail.controls['currentemail'].value ===
      this.changeEmail.controls['newemail'].value &&
      this.changeEmail.controls['currentemail'].value !== '' &&
      this.changeEmail.controls['newemail'].value !== ''
    ) {
      this.samenew_email = false;
    } else {
      this.samenew_email = true;
    }

    if (this.changeEmail.valid && this.samenew_email) {
      this.isLoading = true;
      let payload = {
        UserName: this.localuser,
        CurrentEmail: this.changeEmail.value.currentemail,
        NewEmail: this.changeEmail.value.newemail,
      };

      this.api.changeEmail(JSON.stringify(payload)).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.status == 'Success') {
            this.toaster.success(res.message);
            setTimeout(() => {
              this.tokenStorage.signOut();
              this.router.navigate(['/login']).then(() => {
                window.location.reload();
              });
            }, 3000);
          }
        },
        (err: any) => {
          if (err.status == 500) {
            this.isLoading = false;
            this.toaster.error(err.error.message);
          } else {
            this.isLoading = false;
            this.toaster.error('something went wrong.');
          }
        }
      );
    }
  }

  /* new email blur event :- email id validation */
  newemailblur() {
    if (
      this.changeEmail.controls['currentemail'].value ===
      this.changeEmail.controls['newemail'].value &&
      this.changeEmail.controls['currentemail'].value !== '' &&
      this.changeEmail.controls['newemail'].value !== ''
    ) {
      this.samenew_email = false;
    } else {
      this.samenew_email = true;
    }
  }
}
