<div class="wrapper">

  <div class="ae_innbox">
    <div class="inn_container">
      <div class="umin_sec">
        <div class="umtp_act">
          <div class="umacts">

          </div>
        </div>


        <div class="umrt_box roll_mng">
          <label class="srch_mlbl">Search</label>
          <div class="umrt_itm">
            <div class="umrt_inputbox">
              <div class="umrt_input">
                <input type="text" class="form-control" placeholder="Role Name" [(ngModel)]="textBoxValue" />
              </div>
              <div class="umrt_btn">
                <a class="def_btn bg_blue" (click)="searchRoleSubmit()">Search</a>
                <a class="def_btn bg_lgray" (click)='clearTextBoxValue()'>Clear</a>
              </div>
            </div>
            <a class="def_btn bg_lgray" (click)="addRoleRedirect()"><i class="fa-solid fa-plus"></i> Add Role</a>
          </div>
        </div>


        <div class="ums_mbtbl umrtbl">
          <div class="tbl-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th (click)="sortRole('role')">Role<a title="Order"><i class="fa-solid fa-sort"></i></a></th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let role of Roles | orderBy:keyvalue:reverse | paginate : {itemsPerPage:itemsPage,currentPage:page , totalItems:userData}">
                  <td>{{role.roleName}}</td>
                  <td><a (click)="editRolePage(role.roleName,role.roleId)" title="Edit Role"><i
                        class="fas fa-solid fa-pen-to-square"></i></a></td>
                  <td><a (click)="showDeleteUserConfirmation(role)" title="Delete Role"><i
                        class="fas fa-solid fa-trash-can"></i></a></td>
                </tr>



              </tbody>
            </table>
          </div>

          <div *ngIf="isrecordnotfound" class="norecordfound">
            No records available
          </div>

          <div class="mtsp_btmpart">
            <div class="mtsp_pgebox">
              <div class="mtsp_psize">
                <label>Page Size</label>
                <select class="form-control" (change)="pageSelect()" [(ngModel)]="selectpage">
                  <option *ngFor="let selectedPage of selectedPage" [ngValue]="selectedPage">{{selectedPage}}</option>
                </select>
              </div>
              <div class="mtsp_recbox">
                <label>Records:</label>
                <p><span>{{pageStartFrom}}{{pageEndAt}}</span>of {{userData}}&nbsp;&nbsp;&nbsp; Page<span>{{page}} of
                    {{floorItempage}}</span></p>
              </div>
            </div>
            <div class="pagination">
              <li><a [ngClass]="{'anchor-disable' : isfirstPage}" (click)="firstPage()">
                  << </a>
              </li>
              <pagination-controls [maxSize]="maxSize" [responsive]="responsive" [previousLabel]="labels.previousLabel"
                [nextLabel]="labels.nextLabel" (pageChange)="handlePageChange($event)">
              </pagination-controls>
              <li><a [ngClass]="{'anchor-disable' : isfirstPage}" (click)="lastPage()">>></a></li>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- deleterole popup -->

<div id="eroleModal" class="srem_modbox" [ngStyle]="{'display':deleteRoleDisplay}">
  <div class="sremod_content">
    <div class="sremod_header">
      <span (click)="noChanges()" class="close">×</span>
      <h3>Delete Role</h3>
    </div>
    <div class="sremod_body">
      <form class="sremod_frm" action="">
        <div class="srfc_control ernapbox">
          <label class="srfc_lbl">Are you sure you want to delete Role?</label>
          <div class="umrt_btn mtop20">
            <a class="def_btn bg_blue" (click)="deleteRole()">YES</a>
            <a class="def_btn bg_blue" (click)="noChanges()">NO</a>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>




<div id="auser_box" class="srem_modbox">
  <div class="sremod_content">
    <div class="sremod_header">
      <span class="close" onclick="document.getElementById('sremodal').style.display='none';">×</span>
      <h3>Add New User</h3>
    </div>
    <div class="sremod_body">
      <form class="sremod_frm" action="Manage-Timesheet.html">
        <div class="srfc_control">
          <label class="srfc_lbl">User</label>
          <input type="text" name="user" class="form-control" placeholder="User">
        </div>
        <label class="agpass_lbl"><input type="checkbox">
          <p>Auto Generate Password</p>
        </label>
        <div class="srfc_control">
          <label class="srfc_lbl">First Name</label>
          <input type="text" name="fname" class="form-control" placeholder="First Name">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Middle Name</label>
          <input type="text" name="fname" class="form-control" placeholder="Middle Name">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">First Name</label>
          <input type="text" name="fname" class="form-control" placeholder="First Name">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Email</label>
          <input type="text" name="mail" class="form-control" placeholder="Email">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Role</label>
          <select class="form-control">
            <option>Select Role</option>
            <option>Role 1</option>
            <option>Role 2</option>
            <option>Role 3</option>
          </select>
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Title</label>
          <input type="text" name="title" class="form-control" placeholder="Title">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Phone</label>
          <input type="number" name="phone" class="form-control" placeholder="Phone">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Employee Id</label>
          <input type="email" name="empid" class="form-control" placeholder="Employee Id">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Email</label>
          <input type="email" name="mail" class="form-control" placeholder="Email">
        </div>
        <div class="srfc_control">
          <label class="srfc_lbl">Payroll</label>
          <select class="form-control">
            <option>Select</option>
            <option>Payroll 1</option>
            <option>Payroll 2</option>
            <option>Payroll 3</option>
          </select>
        </div>

        <div class="srfc_control">
          <label class="srfc_lbl">Company Name</label>
          <select class="form-control">
            <option>Select Company</option>
            <option>Company 1</option>
            <option>Company 2</option>
            <option>Company 3</option>
          </select>
        </div>

        <div class="srfc_control">
          <label class="srfc_lbl">Comment</label>
          <textarea class="form-control" rows="5" cols="20"></textarea>
        </div>


        <div class="srfc-actrow">
          <button class="def_btn bg_blue">Sign Up</button>
          <button class="def_btn bg_lgray">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div>
  <app-loader *ngIf="isloading"></app-loader>
</div>



