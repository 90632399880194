import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { TokenStorageService } from './token.service';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root'
})
export class DeleteUserService {

  constructor(private http: HttpClient, private tokenService: TokenStorageService, private commonService: CommonService) { }
  //called from landing pagecomponent, having data of clicked row.
  userDelete(data: any) {
    return this.commonService.save(environment.endPointAPI + commonClass.Authenticate + "/delete-user", data)
  }
}
