import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token.service';
import { CookieService } from 'ngx-cookie-service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class headerComponent implements OnInit {
  @Input() currentvalue: any;

  routename = '';
  public localuser: any;

  constructor(
    private tokenService: TokenStorageService,
    private router: Router,
    private readonly cookieService: CookieService
  ) {
    $(document).ready(function () {
      $('.openMenu').click(function (e: any) {
        $('.openMenu').toggle();
        e.preventDefault();
      });
      $(document).click(function (e: any) {
        if (!$(e.target).closest('.header_dropDown, .openMenu').length) {
          $('.openMenu').hide();
        }
      });
    });
  }

  ngOnInit(): void {
    this.localuser = this.cookieService.get('username');
  }

  ngOnChanges() {
    if (this.currentvalue == '/Add-role') {
      this.routename = 'Add Role';
    }
    if (this.currentvalue == '/FAQ') {
      this.routename = 'FAQ';
    }
    if (this.currentvalue == '/RoleManagement') {
      this.routename = 'Role Management';
    }
    if (this.currentvalue == '/EditRole') {
      this.routename = 'Edit Role';
    }
    if (this.currentvalue == '/ManageUser') {
      this.routename = 'Manage User';
    }
  }

  menuOpen() {
    $('.openMenu').slideToggle();
  }

  linkFaq() {
    this.router.navigate(['FAQ']);
    $('.openMenu').slideToggle();
  }

  /* Logout */
  singOut() {
    this.tokenService.signOut();
  }
}
