import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetRoleService } from '../../services/get-role.service';
import { SharedDataService } from '../../services/shared-data.service';
import { Rightid } from '../../interface/RightsId';
import { DeleteRoleService } from '../../services/delete-role.service';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { SearchRoleService } from '../../services/search-role.service';

@Component({
  selector: 'app-manage-role',
  templateUrl: './manage-role.component.html',
  styleUrls: ['./manage-role.component.scss'],
})
export class ManageRoleComponent implements OnInit {
  textBoxValue: string = '';
  roleDeleteClickId: any;
  isloading: Boolean = false;
  deleteRoleDisplay = 'none';
  collectDeleteRoleData: any;
  RightsidDataObj!: Rightid;
  Roles: any;
  pageNo: any;
  selectpage: any;
  userData: any;
  pageStartFrom: any;
  pageEndAt: any;
  selectedPage: any = [50, 100, 150, 200, 250, 300, 350];
  public itemsPage: any;
  public totalpagesitem: any;
  public floorItempage: any;
  page: number = 1;
  public maxSize: number = 7;
  public responsive: boolean = true;
  public labels: any = {
    previousLabel: '<',
    nextLabel: '>',
  };
  isfirstPage: any = true;
  isrecordnotfound: any = false;
  // function for getting roles from database->calling getrole function in getRole services,
  constructor(
    private router: Router,
    private api: GetRoleService,
    private Shared: SharedDataService,
    private DeleteRoleapi: DeleteRoleService,
    private toaster: ToastrService,
    private searchRoleApi: SearchRoleService
  ) {
    this.selectpage = 50;
    this.getRoleWithPagination();
  }

  ngOnInit() {}

  getRoleWithPagination() {
    this.selectpage = this.selectpage;
    this.isloading = true;
    this.api.getRole(this.page, this.selectpage).subscribe((data: any) => {
      this.Roles = data.roleModel;
      this.userData = data.totalCount;
      if (this.userData === 0) {
        this.isrecordnotfound = true;
      } else {
        this.isrecordnotfound = false;
      }

      this.pagination();
      this.pageStartFrom = (this.page - 1) * this.selectpage + 1;
      if (this.Roles.length < this.selectpage) {
        this.pageEndAt = (this.page - 1) * this.selectpage + this.Roles.length;
        this.pageEndAt = '-' + this.pageEndAt;
      } else {
        this.pageEndAt = this.page * this.selectpage;
        this.pageEndAt = '-' + this.pageEndAt;
      }
      if (this.userData == this.pageStartFrom) {
        this.pageEndAt = '';
      }
    });
  }

  pagination() {
    if (this.Roles == '' && this.userData > 0) {
      this.page = 1;
      this.searchRoleSubmit();
    }
    this.itemsPage = this.selectpage;
    this.selectpage = this.selectpage;
    this.totalpagesitem = this.userData / this.selectpage;
    this.floorItempage = Math.ceil(this.totalpagesitem);
    this.isloading = false;
    if (this.floorItempage > 1) {
      this.isfirstPage = false;
    } else {
      this.isfirstPage = true;
    }
  }
  //function called on add role click
  addRoleRedirect() {
    this.router.navigate(['Add-role']);
  }

  //function for getting the role name of cliked row->calling setRole function shared data service.
  editRolePage(roleName: any, RoleId: string) {
    this.isloading = true;
    this.Shared.setRole(roleName);
    this.Shared.setRoleId(RoleId);
    this.router.navigate(['EditRole']);

    this.isloading = false;
  }
  keyvalue: string = 'role';
  reverse: boolean = false;
  sortRole(key: string) {
    this.keyvalue = key;
    this.reverse = !this.reverse;
  }
  pageSelect() {
    this.itemsPage = this.selectpage;
    this.totalpagesitem = this.userData / this.selectpage;
    this.floorItempage = Math.ceil(this.totalpagesitem);
    this.page = 1;
    this.pageNo = 1;
    if (this.textBoxValue !== '') {
      this.searchRoleSubmit();
    } else {
      this.getRoleWithPagination();
    }
    this.pagination();
  }

  handlePageChange(event: number): void {
    this.pageNo = event;
    this.page = event;
    if (this.textBoxValue !== '') {
      this.searchRoleSubmit();
    } else {
      this.getRoleWithPagination();
    }
  }

  firstPage() {
    this.page = 1;
    this.pageNo = 1;
    if (this.textBoxValue !== '') {
      this.searchRoleSubmit();
    } else {
      this.getRoleWithPagination();
    }
  }
  lastPage() {
    this.page = this.floorItempage;
    if (this.textBoxValue !== '') {
      this.searchRoleSubmit();
    } else {
      this.getRoleWithPagination();
    }
  }

  showDeleteUserConfirmation(roleOnClick: any) {
    this.deleteRoleDisplay = 'block';
    this.roleDeleteClickId = roleOnClick.roleId;
  }
  noChanges() {
    this.deleteRoleDisplay = 'none';
  }
  deleteRole() {
    this.isloading = true;
    this.deleteRoleDisplay = 'none';
    this.DeleteRoleapi.deleteRole(this.roleDeleteClickId).subscribe(
      (res: any) => {
        if (res.status == 'Success') {
          this.getRoleWithPagination();
          this.toaster.success(res.message);
          this.textBoxValue = '';
          this.isloading = false;
        }
      },
      (err: any) => {
        this.toaster.error(err.message);
        this.textBoxValue = '';
        this.isloading = false;
      }
    );
  }

  searchRoleSubmit() {
    this.textBoxValue = this.textBoxValue.trim();

    if (this.textBoxValue !== '') {
      this.isloading = true;

      const searchValue = {
        Name: this.textBoxValue,
        pageNo: this.page,
        pageSize: this.selectpage,
      };
      this.searchRoleApi.searchRole(searchValue).subscribe(
        (res: any) => {
          this.Roles = res.roleModel;
          this.userData = res.totalCount;

          if (this.userData === 0) {
            this.isrecordnotfound = true;
          } else {
            this.isrecordnotfound = false;
          }
          this.pagination();

          this.pageStartFrom = (this.page - 1) * this.selectpage + 1;
          if (this.Roles.length < this.selectpage) {
            this.pageEndAt =
              (this.page - 1) * this.selectpage + this.Roles.length;
            this.pageEndAt = '-' + this.pageEndAt;
          } else {
            this.pageEndAt = this.page * this.selectpage;
            this.pageEndAt = '-' + this.pageEndAt;
          }
          if (this.userData == this.pageStartFrom) {
            this.pageEndAt = '';
          }
          this.isloading = false;
        },
        (err: any) => {
          this.isloading = false;
        }
      );
    }
  }
  clearTextBoxValue() {
    this.isloading = true;
    this.textBoxValue = '';
    this.getRoleWithPagination();
    this.isloading = false;
  }
}
