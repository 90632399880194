<div class="wrapper">
  <div class="loginsec_bg">
    <div class="loginsquarbox_bg">
      <div class="login_box">
        <form class="logpage_frm logfrm" [formGroup]="frmchangePassword">
          <div class="sqqlogo">
            <img src="../../../assets/images/SixQuotumQuixLogos.png" alt="Six Quotum Quix" />
          </div>
          <div class="logo trslogo">
            <a href="/ManageUser"><img src="../../../assets/images/UMS_White_LOGO.svg" alt="logo"></a>
          </div>


          <h2>Change Password</h2>


          <div class="lfrm_control lpass">
            <label class="lfrm_lbl">Current Password<sup>*</sup></label>
            <input formControlName="currentPassword" class="input100" type="password" name="password"
              placeholder="Type Current password">

            <div *ngIf="issubmitChangePassword && f['currentPassword'].errors" class="text-danger">
              <div *ngIf="f['currentPassword'].errors['required']">Current Password is required.</div>
              <div *ngIf="f['currentPassword'].errors['pattern']">Password must be min in 7 character with at-least one
                numeric, one character, one special character.</div>
              <div class="lfrm-fcusline"></div>
            </div>
          </div>
          <div class="lfrm_control lpass">
            <label class="lfrm_lbl">New Password<sup>*</sup></label>
            <input (blur)="newpasswordblur()" formControlName="newPassword" class="input100" type="password"
              name="password" placeholder="Type New password">
            <div *ngIf="issubmitChangePassword && f['newPassword'].errors" class="text-danger">
              <div *ngIf="f['newPassword'].errors['required']">New Password is required.</div>
              <div *ngIf="f['newPassword'].errors['pattern'] &&
            samenew_pass">Password must be min in 7 character with at-least one
                numeric, one character, one special character.</div>
              <div class="lfrm-fcusline"></div>
            </div>



            <span class="text-danger" *ngIf="
          !samenew_pass && issubmitChangePassword">New
              Password can't be same as Current Password</span>
            <div class="lfrm-fcusline"></div>
          </div>

          <div class="lfrm_control lpass">
            <label class="lfrm_lbl">Confirm New Password<sup>*</sup></label>
            <input formControlName="confirmPassword" class="input100" type="password" name="password"
              placeholder="Type Confirm password">

            <div *ngIf="issubmitChangePassword && f['confirmPassword'].errors" class="text-danger">
              <div *ngIf="f['confirmPassword'].errors['required']">Confirm Password is required.</div>
              <div *ngIf="f['confirmPassword'].errors['pattern']">Password must be min in 7 character with at-least one
                numeric, one character, one special character.</div>
              <div *ngIf="f['confirmPassword'].errors['mustMatch']">
                Confirm password does not match</div>
              <div class="lfrm-fcusline"></div>
            </div>
          </div>

          <div class="cpas_btns cusrow">
            <button class="def_btn bg_blue" (click)="btnsubmitPassword()">Change Password</button>
            <button class="def_btn bg_lgray" (click)="btncancel()">Cancel</button>
          </div>


        </form>
        <div *ngIf="isloading">
          <app-loader></app-loader>
        </div>
      </div>
    </div>
  </div>
</div>