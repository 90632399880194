import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Register } from '../../interface/register';
import { RegisterService } from '../../services/register.service';
import { resetPassword } from '../../interface/resetPassword';
import { DeleteUserService } from './../../services/delete-user.service';
import { deleteUser } from './../../interface/deleteUser';
import { ToastrService } from 'ngx-toastr';
import { GetUsersService } from '../../services/get-users.service';
import { UserStatus } from 'src/app/interface/user-status';
import { UserStatusService } from 'src/app/services/user-status.service';
import { UsersLockUnlockService } from 'src/app/services/users-lock-unlock.service';
import { EditUserIdService } from 'src/app/services/edit-user-id.service';
import { ExcelService } from 'src/app/helper/excel.service';
import { GetRoleService } from 'src/app/services/get-role.service';
import { ForgetpasswordService } from 'src/app/services/forgetpassword.service';
import { EditUserService } from 'src/app/services/edit-user.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserSharingService } from 'src/app/services/user-sharing.service';
import { CookieService } from 'ngx-cookie-service';

declare var $: any;

@Component({
  selector: 'app-Landing_page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  providers: [UserSharingService],
})
export class Landing_pageComponent implements OnInit {
  @ViewChild('dropdownMenu1', { static: false }) dropdownMenu1!: ElementRef;
  @ViewChild('menuModal', { static: false }) menuModal!: ElementRef;
  /* Edit User pop open */
  @ViewChild('openEditUserForm', { static: false })
  openEditUserForm!: ElementRef;
  @ViewChild('menuEditModal', { static: false }) menuEditModal!: ElementRef;
  landingpagemodelObj!: Register;
  resetPasswordObj!: resetPassword;
  deleteUserObj!: deleteUser;
  userstatusobj!: UserStatus;
  details: any = [];
  editUserDetails: any[] = [];
  public usernameedit: any;
  public userName: string = '';
  pageStartFrom: any;
  pageEndAt: any;

  public displayStyle = 'none';
  resetPassDisplay = 'none';
  deleteUserDisplay = 'none';
  collectResetData: any;
  collectDeleteUserData: any;
  page: number = 1;
  public selectpage: any;
  public itemsPage: any;
  public userData: any;
  public totalpagesitem: any;
  public maxSize: number = 7;
  public floorItempage: any;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = true;
  public labels: any = {
    previousLabel: '<',
    nextLabel: '>',
  };
  public displayStyleedit = 'none';
  public displayAddUserForm = false;
  public displayAddUserFormExtra = false;
  public current: any;
  public selectpayroll!: string;
  public selectmanager: any;
  public selectrole!: string;
  public selectcompany!: string;
  selectedItemsRole: string[] = [];
  selectedRolevalue!: string;
  UserNameArray: any = [];
  UserStatusArray: any = [];
  public localuser: any;
  isLoading: boolean = false;
  submitted: boolean = false;
  editsubmitted: boolean = false;

  public AddUserForm!: FormGroup;
  public editUserForm!: FormGroup;
  public searchForm!: FormGroup;
  showhidesearchdiv: boolean = false;
  bindRoles: any;
  userDtails: any;
  public userStatusFlag: boolean = true;
  public userLockFlag: boolean = true;
  public userUnlockFlag: boolean = true;

  key: string = 'firstName';
  orderby: any = 'asc';
  reverse: boolean = true;
  isnamesort: boolean = true;
  ismiddelnamesort: boolean = false;
  isLastNamesort: boolean = false;
  isuserNamesort: boolean = false;
  isemailsort: boolean = false;
  iscompanysort: boolean = false;
  isrolesort: boolean = false;
  isactivesort: boolean = false;
  islockedOutsort: boolean = false;
  pageNo: any = 1;
  issearchAPICall: boolean = false;
  dropdownSettings: IDropdownSettings = {};
  isfirstPage: boolean = true;
  ischecked: any = false;
  isrecordnotfound: boolean = false;
  isuseractive: boolean = false;
  isuserinactive: boolean = false;
  isuserboth: boolean = false;
  isemaildisable: boolean = true;
  isShown: boolean = false; // hidden by default
  roleArraytoDisplay: any = [];
  valuemanager: any;
  manager_val_mes: boolean = true;
  sizeOfPagination: any = 5;
  /* role dropdown bind */
  Role: any = [
    'Admin',
    'Super User',
    'Manager',
    'Internal Employee',
    'External Employee Consutant',
  ];

  /* payroll dropdown bind */
  Payroll: any = ['US', 'C2C', 'Other'];

  /* status dropdown bind */
  Status: any = ['Active', 'Inactive'];

  /* pagination page dropdown bind */
  selectedPage: any = [50, 100, 150, 200, 250, 300, 350];

  constructor(
    private toaster: ToastrService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private router: Router,
    private api: RegisterService,
    private deleteUserApi: DeleteUserService,
    private getuserservice: GetUsersService,
    private userStatusService: UserStatusService,
    private usersLockUnlockService: UsersLockUnlockService,
    private editUserIdService: EditUserIdService,
    private forgetpasswordService: ForgetpasswordService,
    private excelService: ExcelService,
    private getRoleService: GetRoleService,
    private editUserService: EditUserService,
    private readonly cookieService: CookieService
  ) {
    $(document).ready(function () {
      $('.dropOpen2').click(function (e: any) {
        $('.dropOpen2').toggle();
        e.preventDefault();
      });
      $(document).click(function (e: any) {
        if (!$(e.target).closest('.toggle_menu, .dropOpen2').length) {
          $('.dropOpen2').hide();
        }
      });
    });

    $(document).ready(function () {
      $('.dropOpen').click(function (e: any) {
        $('.dropOpen').toggle();
        e.preventDefault();
      });
      $(document).click(function (e: any) {
        if (!$(e.target).closest('.availableOption_toggle, .dropOpen').length) {
          $('.dropOpen').hide();
        }
      });
    });

    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target == this.menuModal.nativeElement) {
        this.displayStyle = 'none';
      }
    });

    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target !== this.openEditUserForm?.nativeElement &&
        e.target == this.menuEditModal?.nativeElement
      ) {
        this.displayStyleedit = 'none';
      }
    });
  }

  ngOnInit(): void {
    this.isShown = false;
    this.selectedRolevalue = '';
    this.selectpayroll = 'US';

    this.roleArraytoDisplay = [];

    this.selectpage = 50;
    this.itemsPage = 50;
    this.getRole();

    this.getAllUsers(this.key, this.orderby);
    this.localuser = this.cookieService.get('username');

    this.selectedItemsRole = new Array<string>();
    /* add user form create */
    this.AddUserForm = this.formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(40),

          Validators.pattern('^[a-zA-Z][a-zA-Z0-9 ._@//]*$'),
        ],
      ],
      firstName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z]*$'),
          Validators.maxLength(20),
        ],
      ],
      middleName: [
        '',
        [Validators.pattern('^[a-zA-Z]*$'), Validators.maxLength(20)],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z]*$'),
          Validators.maxLength(20),
        ],
      ],
      email: ['', [Validators.required, Validators.maxLength(255)]],

      title: ['', [Validators.required, Validators.maxLength(100)]],
      phone: [
        '',
        [
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern('^[0-9]*$'),
        ],
      ],

      payroll: ['Select', Validators.required],

      company: [''],
      comment: ['', [Validators.maxLength(50)]],
    });

    /* edit form create */
    this.editUserForm = this.formBuilder.group({
      user: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(40),
          Validators.pattern('^[a-zA-Z][a-zA-Z0-9 ._@//]*$'),
        ],
      ],
      firstName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z]*$'),
          Validators.maxLength(20),
        ],
      ],
      middleName: [
        '',
        [Validators.pattern('^[a-zA-Z]*$'), Validators.maxLength(20)],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z]*$'),
          Validators.maxLength(20),
        ],
      ],
      email: [
        { value: '', disabled: true },
        [Validators.required, Validators.maxLength(255)],
      ],
      role: ['Select Role', Validators.required],
      title: ['', [Validators.required, Validators.maxLength(100)]],
      phone: [
        '',
        [
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern('^[0-9]*$'),
        ],
      ],

      payroll: ['Select', Validators.required],

      company: [''],
      comment: ['', [Validators.maxLength(50)]],
      userId: [],
      manager: [],
    });

    /*  searchForm group create */
    this.searchForm = this.formBuilder.group({
      firstName: [''],
      company: [''],
      lastName: [''],
      userName: [''],
      email: [''],
      role: [''],
      status: [''],
      lockStatus: [''],
    });
  }

  // Validation function for Add user form
  get valid() {
    return this.AddUserForm.controls;
  }
  get validedit() {
    return this.editUserForm.controls;
  }

  /* download excel sheet click button event */
  btndownload(): any {
    var heading = [
      'First Name',
      -'Last Name',
      'Username',
      'Email',
      'Status',
      'Lock Status',
      'Role',
    ];

    let status: any;

    this.searchForm.patchValue({
      firstName: this.searchForm.controls['firstName'].value.trim(),
      company: this.searchForm.controls['company'].value.trim(),
      lastName: this.searchForm.controls['lastName'].value.trim(),
      userName: this.searchForm.controls['userName'].value.trim(),
      email: this.searchForm.controls['email'].value.trim(),
      role: this.searchForm.controls['role'].value.trim(),
    });

    if (this.searchForm.controls['status'].value === 'true') {
      status = '1';
    } else if (this.searchForm.controls['status'].value === 'false') {
      status = '0';
    } else {
      if (
        this.searchForm.controls['firstName'].value !== '' ||
        this.searchForm.controls['company'].value !== '' ||
        this.searchForm.controls['lastName'].value !== '' ||
        this.searchForm.controls['userName'].value !== '' ||
        this.searchForm.controls['email'].value !== '' ||
        this.searchForm.controls['role'].value !== ''
      ) {
        status = '';
      } else {
        status = '1';
      }
    }

    let islockedstatus: any;

    if (this.searchForm.controls['lockStatus'].value === 'true') {
      islockedstatus = '1';
    } else if (this.searchForm.controls['lockStatus'].value === 'false') {
      islockedstatus = '0';
    } else {
      islockedstatus = '';
    }

    let payload = {
      FirstName: this.searchForm.controls['firstName'].value
        ? this.searchForm.controls['firstName'].value
        : null,
      company: this.searchForm.controls['company'].value
        ? this.searchForm.controls['company'].value
        : null,
      LastName: this.searchForm.controls['lastName'].value
        ? this.searchForm.controls['lastName'].value
        : null,
      UserName: this.searchForm.controls['userName'].value
        ? this.searchForm.controls['userName'].value
        : null,
      Email: this.searchForm.controls['email'].value
        ? this.searchForm.controls['email'].value
        : null,
      Status: status,
      IsLock: islockedstatus,
      Role: this.searchForm.controls['role'].value
        ? this.searchForm.controls['role'].value
        : null,
    };

    this.getuserservice.downloadUser(payload).subscribe((data: any) => {
      if (data !== null && data.length > 0) {
        let sortarray: any;
        let direction = this.reverse ? 1 : -1;
        let columnname = this.key;
        sortarray = data.sort(function (a: any, b: any) {
          if (a[columnname] < b[columnname]) {
            return -1 * direction;
          } else if (a[columnname] > b[columnname]) {
            return 1 * direction;
          } else {
            return 0;
          }
        });

        this.excelService.exportExcel(sortarray);
      } else {
        this.toaster.warning('No records available.');
      }
    });
  }

  /* select page for page szie change */
  pageSelect() {
    this.itemsPage = this.selectpage;
    this.pageNo = 1;
    this.page = 1;
    if (this.issearchAPICall) {
      this.btnSubmit();
    } else {
      this.getAllUsers(this.key, this.orderby);
    }
  }

  /* pervious and next page change */
  handlePageChange(event: number): void {
    this.pageNo = event;
    this.page = event;
    this.getAllUsers(this.key, this.orderby);
  }

  /* Get all role list*/
  getRole(): any {
    this.getRoleService.getroleswithtoken().subscribe((data: any) => {
      this.bindRoles = data;

      this.bindRoles.forEach((element: { isSelected: boolean }) => {
        element.isSelected = false;
      });
    });
  }

  /* Code for the Available Action and Available Options
   This Function is use to Display the Available Options dropdown. */
  openDropDown() {
    $('.dropOpen').slideToggle();
  }

  /* This Function is use to Display the Available Actions dropdown.. */
  openDropDown2() {
    $('.dropOpen2').slideToggle();
  }

  /* Link to the Manage user in available options dropdown.. */
  linkManagerUser() {
    this.router.navigate(['ManageUser']);
    $('.dropOpen').slideToggle();
  }
  // Link to the Role Management in available options dropdown..
  linkManagerRole() {
    this.router.navigate(['RoleManagement']);
    $('.dropOpen').slideToggle();
  }
  /* Link to the add role in available options dropdown.. */
  linkAddRole() {
    this.router.navigate(['Add-role']);
    $('.dropOpen').slideToggle();
  }
  /* Display the subdropdown */
  subDropDown() {
    $('.subdropdown').slideToggle();
  }

  /* This Function is use to Display the modal Add User Form. */
  openModal() {
    this.displayStyle = 'block';
    $('.dropOpen').slideToggle();
  }

  /* This Function is use to Close the modal Add User Form. */
  close() {
    this.displayStyle = 'none';
    this.submitted = false;
    this.AddUserForm.reset();
  }

  /* Clear Search Form */
  clearSearchForm() {
    this.searchForm.patchValue({
      firstName: '',
      company: '',
      lastName: '',
      userName: '',
      email: '',
      role: '',
      status: [''],
      lockStatus: [''],
    });
    this.getAllUsers(this.key, this.orderby);
  }

  /* This Function is use to open the modal Edit User Form. */
  openEditUser(data: any) {
    if (!data.active) {
      this.toaster.warning('You cannot edit inactive users.');
      return;
    }
    this.isLoading = true;
    this.displayStyleedit = 'block';
    this.editsubmitted = false;
    const userNameforEdit = data.userName;

    this.editUserIdService.getedituser(userNameforEdit).subscribe(
      (res: any) => {
        this.isLoading = false;

        this.selectmanager = res.managerId;
        this.selectedRolevalue =
          res.roleName && res.roleName.length > 0 ? res.roleName[0] : '';

        this.editUserForm.patchValue({
          user: res.username,
          firstName: res.firstName,
          middleName: res.middleName,
          lastName: res.lastName,
          email: res.email,

          role: this.selectedRolevalue,
          title: res.title,
          phone: res.phoneNumber,
          payroll: res.payroll,
          company: '',
          comment: res.comment,
          userId: res.userId,

          manager: '',
        });

        return res;
      },
      (err: any) => {
        this.isLoading = false;
      }
    );
  }

  /* Function is use to close the modal Edit User Form. */
  closeEdit() {
    this.displayStyleedit = 'none';
    this.isShown = false;
  }

  /* Select Check box dropdown in edit user form */
  selectRole() {
    $('.roleopen').slideToggle();
  }

  /* Reset password for selected user */
  resetPassword() {
    this.resetPassDisplay = 'none';
    this.isLoading = true;
    this.resetPasswordObj = this.collectResetData;
    let payload = {
      UserName: this.collectResetData.userName,
      forgetPasswordFlag: false,
      isunLockByAdmin: true,
    };

    this.forgetpasswordService.forgetPassword(payload).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.getAllUsers(this.key, this.orderby);
        if (res.status == 'Success') {
          this.toaster.success(res.message);
        }
      },
      (err: any) => {
        this.isLoading = false;
        if (err && err.error && err.error.message) {
          this.toaster.error(err.error.message);
        } else {
          this.toaster.error('Please enter correct details.');
        }
      }
    );
  }

  /* delete user */
  deleteUser() {
    this.isLoading = true;
    this.deleteUserDisplay = 'none';

    const deletevalue = {
      UserName: this.collectDeleteUserData,
    };

    this.deleteUserApi.userDelete(deletevalue).subscribe(
      (res: any) => {
        //.log(res);
        if (res.status == 'Success') {
          this.toaster.success(res.message);
          this.searchForm.patchValue({
            firstName: '',
            company: '',
            lastName: '',
            userName: '',
            email: '',
            role: '',
            status: [''],
            lockStatus: [''],
          });
          this.getAllUsers(this.key, this.orderby);
        }
        this.isLoading = false;

        return res;
      },
      (err: any) => {
        this.isLoading = false;
        if (err && err.error && err.error.message) {
          this.toaster.error(err.error.message);
        } else {
          this.toaster.error('Please enter correct details.');
        }
      }
    );
  }

  filterByName() {
    let Namearr = [];
    for (let i = 0; i < this.userDtails.length; i++) {
      Namearr.push(this.userDtails[i].name);
    }
    Namearr.sort();
  }

  isFirstPage(): boolean {
    return this.pageNo === 1;
  }
  /* Pagination function to redirect to first page */
  firstPage() {
    this.page = 1;
    this.pageNo = 1;
    if (this.issearchAPICall) {
      this.btnSubmit();
    } else {
      this.getAllUsers(this.key, this.orderby);
    }
  }

  /* Pagination function to redirect to last page */
  lastPage() {
    this.pageNo = this.floorItempage;
    this.page = this.floorItempage;
    if (this.issearchAPICall) {
      this.btnSubmit();
    } else {
      this.getAllUsers(this.key, this.orderby);
    }
  }
  isLastPage(): boolean {
    return this.pageNo === this.floorItempage || this.totalpagesitem === 0;
  }

  /* Get all user list */
  getAllUsers(ColumnName: any, Sorttype: any) {
    this.isLoading = true;
    this.issearchAPICall = false;
    this.getuserservice
      .getUser(this.pageNo, this.selectpage, ColumnName, Sorttype)
      .subscribe((res: any) => {
        this.isLoading = false;
        this.userDtails = res.getUsersModels;
        this.userData = res.totalCount;
        this.userDtails.every((p: any) => p.checked);
        this.totalpagesitem = this.userData / this.selectpage;
        this.floorItempage = Math.ceil(this.totalpagesitem);

        if (this.userData === 0) {
          this.isrecordnotfound = true;
          this.sizeOfPagination = 0;
        } else {
          this.isrecordnotfound = false;
          this.sizeOfPagination = 5;
        }

        this.maxSize = 7;

        this.pageStartFrom = (this.pageNo - 1) * this.selectpage + 1;
        if (this.userDtails.length < this.selectpage) {
          this.pageEndAt =
            (this.pageNo - 1) * this.selectpage + this.userDtails.length;
          this.pageEndAt = '-' + this.pageEndAt;
        } else {
          this.pageEndAt = this.pageNo * this.selectpage;
          this.pageEndAt = '-' + this.pageEndAt;
        }
        if (this.userData == this.pageStartFrom) {
          this.pageEndAt = '';
        }
        if (this.floorItempage > 1) {
          this.isfirstPage = false;
        } else {
          this.isfirstPage = true;
        }
      });
  }

  /* table all checkbox select (all data select in table)*/
  isAllCheckBoxChecked() {
    if (
      this.userDtails !== undefined &&
      this.userDtails !== null &&
      this.userDtails.length > 0
    ) {
      return this.userDtails.every((p: any) => p.checked);
    }
  }

  /* table all checkbox change (all data select in table)*/
  checkAllCheckBox(ev: any) {
    this.userStatusFlag = true;
    this.userLockFlag = true;
    this.userUnlockFlag = true;
    this.isuseractive = false;
    this.isuserinactive = false;
    this.isuserboth = false;
    this.userDtails.forEach((x: any) => (x.checked = ev.target.checked));
    if (ev.target.checked) {
      const userinactive = this.userDtails.filter(
        (word: any) => word.active === false
      );

      const useractive = this.userDtails.filter(
        (word: any) => word.active === true
      );

      if (userinactive.length > 0) {
        if (useractive.length > 0) {
          this.isuserinactive = false;
          this.isuseractive = true;
        } else {
          this.isuserinactive = true;
          this.isuseractive = false;
        }
      } else {
        this.isuserinactive = false;
        this.isuseractive = true;
      }

      if (userinactive.length > 0 && useractive.length > 0) {
        this.isuserboth = true;
      } else {
        this.isuserboth = false;
      }

      const userlock = this.userDtails.filter(
        (word: any) => word.isLockedOut === true
      );

      if (userlock.length > 0) {
        this.userLockFlag = false;
      }

      const userunlock = this.userDtails.filter(
        (word: any) => word.isLockedOut === false
      );

      if (userunlock.length > 0) {
        this.userUnlockFlag = false;
      }

      this.userDtails.forEach((element: any) => {
        this.UserNameArray.push(element.userName);
      });
    } else {
      this.UserNameArray = [];
      this.userUnlockFlag = true;
      this.userLockFlag = true;
      this.userStatusFlag = false;
      this.isuseractive = false;
      this.isuserinactive = false;
      this.isuserboth = false;
    }
  }

  /* search user click button event */
  btnSubmit() {
    this.pageNo = 1;
    this.page = 1;
    this.pageStartFrom = 1;
    this.pageEndAt = '';
    this.isLoading = true;
    this.issearchAPICall = true;
    let status: any;

    if (this.searchForm.controls['status'].value === 'true') {
      status = '1';
    } else if (this.searchForm.controls['status'].value === 'false') {
      status = '0';
    } else {
      status = '';
    }

    let islockedstatus: any;

    if (this.searchForm.controls['lockStatus'].value === 'true') {
      islockedstatus = '1';
    } else if (this.searchForm.controls['lockStatus'].value === 'false') {
      islockedstatus = '0';
    } else {
      islockedstatus = '';
    }

    this.searchForm.patchValue({
      firstName: this.searchForm.controls['firstName'].value.trim(),
      company: this.searchForm.controls['company'].value.trim(),
      lastName: this.searchForm.controls['lastName'].value.trim(),
      userName: this.searchForm.controls['userName'].value.trim(),
      email: this.searchForm.controls['email'].value.trim(),
      role: this.searchForm.controls['role'].value.trim(),
    });

    let payload = {
      FirstName: this.searchForm.controls['firstName'].value,
      CompanyName: this.searchForm.controls['company'].value,
      LastName: this.searchForm.controls['lastName'].value,
      UserName: this.searchForm.controls['userName'].value,
      Email: this.searchForm.controls['email'].value,
      Status: status,
      IsLock: islockedstatus,
      Role: this.searchForm.controls['role'].value,
      PageNo: this.pageNo,
      PageSize: this.selectpage,
      sortColumn: this.key,
      sortOrder: this.orderby,
    };

    this.getuserservice.searchUser(payload).subscribe((res: any) => {
      this.isLoading = false;
      this.userDtails = res.getUsersModels;
      this.userData = res.totalCount;
      if (this.userData === 0) {
        this.isrecordnotfound = true;
        this.sizeOfPagination = 0;
      } else {
        this.isrecordnotfound = false;
        this.sizeOfPagination = 5;
      }

      if (this.userDtails == '' && this.userData > 0) {
        this.pageNo = 1;
        this.btnSubmit();
        this.firstPage();
      }
      this.totalpagesitem = this.userData / this.selectpage;
      this.floorItempage = Math.ceil(this.totalpagesitem);

      this.maxSize = this.userData;

      this.pageStartFrom = (this.pageNo - 1) * this.selectpage + 1;
      if (this.userDtails.length < this.selectpage) {
        this.pageEndAt =
          (this.pageNo - 1) * this.selectpage + this.userDtails.length;
        this.pageEndAt = '-' + this.pageEndAt;
      } else {
        this.pageEndAt = this.pageNo * this.selectpage;
        this.pageEndAt = '-' + this.pageEndAt;
      }
      if (this.userData == this.pageStartFrom) {
        this.pageEndAt = '';
      }
      if (this.floorItempage > 1) {
        this.isfirstPage = false;
      } else {
        this.isfirstPage = true;
      }
    });
  }

  /* search div expand  */
  btnsearch() {
    this.showhidesearchdiv = !this.showhidesearchdiv;
    var acc = document.getElementsByClassName('faq_head');
    var i;

    if (this.showhidesearchdiv) {
      for (i = 0; i < acc.length; i++) {
        acc[i].className += '  active';
        const panel: any = acc[i].nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      }
    } else {
      for (i = 0; i < acc.length; i++) {
        acc[i].classList.remove('active');
        const panel: any = acc[i].nextElementSibling;
        panel.style.maxHeight = null;
      }
    }
  }

  /* sing up button click event (add user) */
  btnAddUser() {
    this.submitted = true;

    if (this.AddUserForm.valid) {
      this.isLoading = true;
      const userObject = {
        Username: this.AddUserForm.value.username,
        Email: this.AddUserForm.value.email,
        FirstName: this.AddUserForm.value.firstName,
        MiddleName: this.AddUserForm.value.middleName,
        LastName: this.AddUserForm.value.lastName,
        Title: this.AddUserForm.value.title,
        PhoneNumber: this.AddUserForm.value.phone.toString(),
        CompanyName: '',
        Comment: this.AddUserForm.value.comment,
        Payroll: this.AddUserForm.value.payroll,
      };

      this.api.postUser(userObject).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.status == 'Success') {
            this.toaster.success(res.message);
            this.AddUserForm.patchValue({
              username: '',
              firstName: '',
              middleName: '',
              lastName: '',
              email: '',
              title: '',
              phone: '',

              payroll: 'Select',
              company: '',
              comment: '',
            });
            this.submitted = false;

            this.displayStyle = 'none';
            this.searchForm.patchValue({
              firstName: '',
              company: '',
              lastName: '',
              userName: '',
              email: '',
              role: '',
              status: [''],
              lockStatus: [''],
            });
            this.getAllUsers(this.key, this.orderby);
          }
          return res;
        },
        (err: any) => {
          if (err.status == 500) {
            this.isLoading = false;

            this.toaster.error(err.error.message);
          } else {
            this.isLoading = false;

            this.toaster.error('something went wrong.');
          }
        }
      );
    }
  }

  /* update user form */
  btnEditUser() {
    this.editsubmitted = true;

    if (this.editUserForm.valid) {
      this.isLoading = true;

      let roleValue = this.editUserForm.value.role['roleName'];
      this.roleArraytoDisplay.push(this.selectedRolevalue);

      this.selectmanager = this.editUserForm.value.manager;

      const edituserObject = {
        Username: this.editUserForm.value.user,
        Email: this.editUserForm.controls['email'].value,
        FirstName: this.editUserForm.value.firstName,
        MiddleName: this.editUserForm.value.middleName,
        LastName: this.editUserForm.value.lastName,
        Title: this.editUserForm.value.title,
        PhoneNumber: this.editUserForm.value.phone.toString(),
        CompanyName: '',
        Comment: this.editUserForm.value.comment,
        Payroll: this.editUserForm.value.payroll,

        RoleName: this.roleArraytoDisplay,
        userId: this.editUserForm.value.userId,

        ManagerId: '',
      };

      this.editUserService.editUser(edituserObject).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.status == 'Success') {
            this.toaster.success(res.message);
            this.searchForm.patchValue({
              firstName: '',
              company: '',
              lastName: '',
              userName: '',
              email: '',
              role: '',
              status: [''],
              lockStatus: [''],
            });
            this.selectedItemsRole = [];
            this.roleArraytoDisplay = [];

            this.editsubmitted = false;
            this.manager_val_mes = true;
            this.getAllUsers(this.key, this.orderby);
            this.selectmanager = '';
            this.displayStyleedit = 'none';
          }
          return res;
        },
        (err: any) => {
          if (err.status == 500) {
            this.isLoading = false;

            this.toaster.error(err.error.message);
          } else {
            this.isLoading = false;
            if (err && err.error && err.error.message) {
              this.toaster.error(err.error.message);
            } else {
              this.toaster.error('something went wrong.');
            }
          }
        }
      );
    }
  }

  /* Closing the reset popup and delete popup */
  noChanges() {
    this.resetPassDisplay = 'none';
    this.deleteUserDisplay = 'none';
  }

  /* reset password confirmation popup open */
  showResetPassConfirmation(data: any) {
    this.collectResetData = data;

    if (this.collectResetData.active == false) {
      this.toaster.warning('You cannot reset password for inactive users.');
    } else if (this.collectResetData.isLockedOut == false) {
      this.toaster.warning('Please select Locked user for Reset Password.');
    } else {
      this.resetPassDisplay = 'block';
    }
  }

  /* delete confirmation pop up open */
  showDeleteUserConfirmation(data: any) {
    this.deleteUserDisplay = 'block';
    this.collectDeleteUserData = data;
  }

  /* sorting array column click wise */
  sortfirstName(key: string) {
    if (key === 'FirstName') {
      this.isnamesort = true;
      this.ismiddelnamesort = false;
      this.isLastNamesort = false;
      this.isuserNamesort = false;
      this.isemailsort = false;
      this.iscompanysort = false;
      this.isrolesort = false;
      this.isactivesort = false;
      this.islockedOutsort = false;
    } else if (key === 'LastName') {
      this.isnamesort = false;
      this.ismiddelnamesort = false;
      this.isLastNamesort = true;
      this.isuserNamesort = false;
      this.isemailsort = false;
      this.iscompanysort = false;
      this.isrolesort = false;
      this.isactivesort = false;
      this.islockedOutsort = false;
    } else if (key === 'UserName') {
      this.isnamesort = false;
      this.ismiddelnamesort = false;
      this.isLastNamesort = false;
      this.isuserNamesort = true;
      this.isemailsort = false;
      this.iscompanysort = false;
      this.isrolesort = false;
      this.isactivesort = false;
      this.islockedOutsort = false;
    } else if (key === 'Email') {
      this.isnamesort = false;
      this.ismiddelnamesort = false;
      this.isLastNamesort = false;
      this.isuserNamesort = false;
      this.isemailsort = true;
      this.isemailsort = false;
      this.isrolesort = false;
      this.isactivesort = false;
      this.islockedOutsort = false;
    } else if (key === 'Roles') {
      this.isnamesort = false;
      this.ismiddelnamesort = false;
      this.isLastNamesort = false;
      this.isuserNamesort = false;
      this.isemailsort = false;
      this.iscompanysort = false;
      this.isrolesort = true;
      this.isactivesort = false;
      this.islockedOutsort = false;
    } else if (key === 'Active') {
      this.isnamesort = false;
      this.ismiddelnamesort = false;
      this.isLastNamesort = false;
      this.isuserNamesort = false;
      this.isemailsort = false;
      this.iscompanysort = false;
      this.isrolesort = false;
      this.isactivesort = true;
      this.islockedOutsort = false;
    } else if (key === 'IsLockedOut') {
      this.isnamesort = false;
      this.ismiddelnamesort = false;
      this.isLastNamesort = false;
      this.isuserNamesort = false;
      this.isemailsort = false;
      this.iscompanysort = false;
      this.isrolesort = false;
      this.isactivesort = false;
      this.islockedOutsort = true;
    } else if (key === 'Company') {
      this.isnamesort = false;
      this.ismiddelnamesort = false;
      this.isLastNamesort = false;
      this.isuserNamesort = false;
      this.isemailsort = false;
      this.iscompanysort = true;
      this.isrolesort = false;
      this.isactivesort = false;
      this.islockedOutsort = false;
    }

    this.key = key;
    this.reverse = !this.reverse;
    if (this.reverse) {
      this.orderby = 'asc';
    } else {
      this.orderby = 'desc';
    }
    // this.userDtails.sort(this.compareValues(this.key, orderby));
    if (this.issearchAPICall) {
      this.btnSubmit();
    } else {
      this.getAllUsers(this.key, this.orderby);
    }
  }

  /* Function for check box click and username get */
  checkBoxChecked(Uname: string) {
    if (!this.UserNameArray.includes(Uname)) {
      this.UserNameArray.push(Uname);
    } else {
      const index = this.UserNameArray.indexOf(Uname);
      if (index > -1) this.UserNameArray.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  /* Function for get the status array of select user by check box */
  checkBoxActive(status: string) {
    this.userStatusFlag == true;
    this.userLockFlag = true;
    this.userUnlockFlag = true;
    this.isuseractive = false;
    this.isuserinactive = false;
    this.isuserboth = false;
    if (!this.UserStatusArray.includes(status)) {
      this.UserStatusArray.push(status);
    } else {
      const index = this.UserStatusArray.indexOf(status);
      if (index > -1) this.UserStatusArray.splice(index, 1); // 2nd parameter means remove one item only
    }

    const userinactive = this.UserStatusArray.filter(
      (word: any) => word.active === false
    );

    const useractive = this.UserStatusArray.filter(
      (word: any) => word.active === true
    );

    if (userinactive.length > 0) {
      if (useractive.length > 0) {
        this.isuserinactive = false;
        this.isuseractive = true;
      } else {
        this.isuserinactive = true;
        this.isuseractive = false;
      }
    } else {
      this.isuserinactive = false;
      this.isuseractive = true;
    }

    if (userinactive.length > 0 && useractive.length > 0) {
      this.isuserboth = true;
    }

    const userlock = this.UserStatusArray.filter(
      (word: any) => word.isLockedOut === true
    );

    if (userlock.length > 0) {
      this.userLockFlag = false;
    }

    const userunlock = this.UserStatusArray.filter(
      (word: any) => word.isLockedOut === false
    );

    if (userunlock.length > 0) {
      this.userUnlockFlag = false;
    }
  }

  /* Function for active action selected user */
  activateUsers() {
    this.isLoading = true;
    if (this.UserNameArray == '') {
      this.toaster.warning('At least one employee must be selected.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.isLoading = false;
    } else if (this.isuserboth == true) {
      this.toaster.warning('Please select inactive users.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isLoading = false;
      this.isuseractive = false;
      this.isuserinactive = false;
      this.isuserboth = true;
      this.getAllUsers(this.key, this.orderby);
    } else if (this.isuseractive == true && this.isuserinactive === false) {
      this.toaster.warning('Please select inactive users.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isLoading = false;
      this.isuseractive = false;
      this.isuserinactive = false;
      this.isuserboth = true;
      this.getAllUsers(this.key, this.orderby);
    } else {
      const userStatusData = {
        UserName: this.UserNameArray,
        IsActive: true,
      };
      this.userStatusService.userStatus(userStatusData).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.status == 'Success') {
            this.UserNameArray = [];
            this.UserStatusArray = [];
            this.userStatusFlag = true;
            this.userLockFlag = true;
            this.userUnlockFlag = true;
            this.toaster.success(res.message);
            this.searchForm.patchValue({
              firstName: '',
              company: '',
              lastName: '',
              userName: '',
              email: '',
              role: '',
              status: [''],
              lockStatus: [''],
            });
            this.getAllUsers(this.key, this.orderby);
          }
          return res;
        },
        (err: any) => {
          this.isLoading = false;
          this.toaster.error(err.error.message);
        }
      );
    }
  }

  /* Function for inactive action selected user */
  deactivateUsers() {
    this.isLoading = true;
    if (this.UserNameArray == '') {
      this.toaster.warning('At least one employee must be selected.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.isLoading = false;
    } else if (this.isuserboth == true) {
      this.toaster.warning('Please select active users.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isuseractive = false;
      this.isuserinactive = false;
      this.isLoading = false;
      this.getAllUsers(this.key, this.orderby);
    } else if (this.isuserinactive == true && this.isuseractive == false) {
      this.toaster.warning('Please select active users.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isuseractive = false;
      this.isuserinactive = false;
      this.isLoading = false;
      this.getAllUsers(this.key, this.orderby);
    } else {
      const userStatusData = {
        UserName: this.UserNameArray,
        IsActive: false,
      };
      this.userStatusService.userStatus(userStatusData).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.status == 'Success') {
            this.UserNameArray = [];
            this.UserStatusArray = [];
            this.userStatusFlag = true;
            this.userLockFlag = true;
            this.userUnlockFlag = true;
            this.toaster.success(res.message);
            this.searchForm.patchValue({
              firstName: '',
              company: '',
              lastName: '',
              userName: '',
              email: '',
              role: '',
              status: [''],
              lockStatus: [''],
            });
            this.getAllUsers(this.key, this.orderby);
          }
          return res;
        },
        (err: any) => {
          this.isLoading = false;
          this.toaster.error(err.error.message);
        }
      );
    }
  }

  /* Function for lock action when selected user */
  lockUsers() {
    this.isLoading = true;
    if (this.UserNameArray == '') {
      this.toaster.warning('At least one employee must be selected.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.isLoading = false;
    } else if (this.isuserboth == true) {
      this.toaster.warning('Please select active users only.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isLoading = false;
      this.isuseractive = false;
      this.isuserinactive = false;
      this.getAllUsers(this.key, this.orderby);
    } else if (this.isuserinactive == true && this.isuseractive == false) {
      this.toaster.warning('Please select active users only.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isLoading = false;
      this.isuseractive = false;
      this.isuserinactive = false;
      this.isuserboth == false;
      this.getAllUsers(this.key, this.orderby);
    } else if (this.userLockFlag == false) {
      this.toaster.warning('Please select unlocked users.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isLoading = false;
      this.isuserboth == false;
      this.getAllUsers(this.key, this.orderby);
    } else {
      const userStatusData = {
        UserName: this.UserNameArray,
        IsLock: true,
        isLockedByAdmin: true,
      };
      this.usersLockUnlockService.userLocked(userStatusData).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.status == 'Success') {
            this.UserNameArray = [];
            this.UserStatusArray = [];
            this.userStatusFlag = true;
            this.userLockFlag = true;
            this.isuseractive = true;
            this.userUnlockFlag = true;
            this.toaster.success(res.message);
            this.searchForm.patchValue({
              firstName: '',
              company: '',
              lastName: '',
              userName: '',
              email: '',
              role: '',
              status: [''],
              lockStatus: [''],
            });
            this.getAllUsers(this.key, this.orderby);
          }
          return res;
        },
        (err: any) => {
          this.isLoading = false;
          this.toaster.error(err.error.message);
        }
      );
    }
  }

  /* Function for unlock action when selected user */
  unlockUsers() {
    this.isLoading = true;
    if (this.UserNameArray == '') {
      this.toaster.warning('At least one employee must be selected.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.isLoading = false;
    } else if (this.isuserboth == true) {
      this.toaster.warning('Please select active users only.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isLoading = false;
      this.isuseractive = false;
      this.isuserinactive = false;
      this.getAllUsers(this.key, this.orderby);
    } else if (this.isuserinactive == true && this.isuseractive == false) {
      this.toaster.warning('Please select active users only.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.isuseractive = false;
      this.isuserinactive = false;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isLoading = false;
      this.getAllUsers(this.key, this.orderby);
    } else if (this.userUnlockFlag == false) {
      this.toaster.warning('Please select locked users.');
      this.searchForm.patchValue({
        firstName: '',
        company: '',
        lastName: '',
        userName: '',
        email: '',
        role: '',
        status: [''],
        lockStatus: [''],
      });
      this.UserNameArray = [];
      this.UserStatusArray = [];
      this.userStatusFlag = true;
      this.userLockFlag = true;
      this.userUnlockFlag = true;
      this.isLoading = false;
      this.getAllUsers(this.key, this.orderby);
    } else {
      const userStatusData = {
        UserName: this.UserNameArray,
        IsLock: false,
      };
      this.usersLockUnlockService.userLocked(userStatusData).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.status == 'Success') {
            this.UserNameArray = [];
            this.UserStatusArray = [];
            this.userStatusFlag = true;
            this.userLockFlag = true;
            this.userUnlockFlag = true;
            this.toaster.success(res.message);
            this.searchForm.patchValue({
              firstName: '',
              company: '',
              lastName: '',
              userName: '',
              email: '',
              role: '',
              status: [''],
              lockStatus: [''],
            });
            this.getAllUsers(this.key, this.orderby);
          }
          return res;
        },
        (err: any) => {
          this.isLoading = false;
          this.toaster.error(err.error.message);
        }
      );
    }
  }

  /* common function for sort array */
  compareValues(key: any, order = 'asc') {
    return function innerSort(a: any, b: any) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  /* Function to get the dropdown checkbox value */
  getCheckValue(event: any, data: string) {
    if (event.target.checked) {
      this.selectedItemsRole.push(data);
    } else {
      this.selectedItemsRole = this.selectedItemsRole.filter((m) => m != data);
    }
  }
}
