import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { TokenStorageService } from './token.service';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  RightsId: any;
  RoleId: any;
  message: string = '';
  constructor(
    private router: Router,
    private http: HttpClient,
    private tokenService: TokenStorageService,
    public commonService: CommonService
  ) { }
  //called from managerole component for setting role name->having role name of clicked row as data.
  setRole(data: any) {
    this.message = data;
    this.router.navigate(['EditRole']);
  }
  // called from edit role component for getting role name from managerole page to edit role page
  getROle() {
    return this.message;
  }
  setRoleId(id: any) {
    this.RoleId = id;
  }
  gerRoleId() {
    return this.RoleId;
  }
  //called from manage role when clicked on edit role to get the granted rights id
  getRightsId(data: any) {
    let token = this.tokenService.getToken();

    const httpOptions: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });

    return this.commonService
      .save(
        environment.endPointAPI + commonClass.Role + '/GetRightsDetailsById',
        data
      )
      .pipe(
        map((res: any) => {
          this.RightsId = res;

          return res;
        })
      );
  }
  //called from edit role page to set the grant rights id on radio check
  setRightsId() {
    return this.RightsId;
  }
}
