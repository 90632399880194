import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    public userLoggedIn = new Subject<boolean>();
    // userloged = this.userLoggedIn.asObservable();

    constructor() {
        this.userLoggedIn.next(false);
    }

    setUserLoggedIn(userLoggedIn: boolean) {
        this.userLoggedIn.next(userLoggedIn);
    }

    getUserLoggedIn(): Observable<boolean> {
        // return this.userLoggedIn.asObservable();
        return this.userLoggedIn.asObservable();
    }
}