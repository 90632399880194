import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AppService } from './AppService.service';
const TOKEN_KEY = 'token';
// const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = 'auth-user';
@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(
    private readonly cookieService: CookieService,
    private router: Router,
    private appService: AppService
  ) { }
  signOut(): void {
    this.cookieService.delete('logincount');
    this.cookieService.delete('TOKEN_KEY');
    this.cookieService.delete('USER_KEY');
    this.cookieService.delete('token');
    this.cookieService.delete('isFirstTimeLogin');
    this.cookieService.delete('auth-token');
    this.cookieService.delete('oldusername');

    var domainName = environment.domain;
    this.cookieService.delete('USER_KEY', '', domainName);
    this.cookieService.delete('token', '', domainName);
    this.cookieService.delete('UserId', '', domainName);

    this.cookieService.delete('rolename', '', domainName);

    var sixconsultingcorpdomain = environment.sixconsultingcorpdomain;
    this.cookieService.delete('USER_KEY', '', sixconsultingcorpdomain);
    this.cookieService.delete('token', '', sixconsultingcorpdomain);
    this.cookieService.delete('UserId', '', sixconsultingcorpdomain);
    this.cookieService.delete('rolename', '', sixconsultingcorpdomain);

    var quotumtechdomain = environment.quotumtechdomain;
    this.cookieService.delete('USER_KEY', '', quotumtechdomain);
    this.cookieService.delete('token', '', quotumtechdomain);
    this.cookieService.delete('UserId', '', quotumtechdomain);
    this.cookieService.delete('rolename', '', quotumtechdomain);

    this.appService.setUserLoggedIn(false);
    this.router.navigate(['/login']);

    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    this.cookieService.delete(TOKEN_KEY, '', environment.domain);
    this.cookieService.delete(TOKEN_KEY, '', environment.sixconsultingcorpdomain);
    this.cookieService.delete(TOKEN_KEY, '', environment.quotumtechdomain);

    this.cookieService.set(TOKEN_KEY, token, 365, '', environment.domain);
    this.cookieService.set(TOKEN_KEY, token, 365, '', environment.sixconsultingcorpdomain);
    this.cookieService.set(TOKEN_KEY, token, 365, '', environment.quotumtechdomain);

    const user = this.getUser();
    if (user.id) {
      this.saveUser({ ...user, token: token });
    }
  }
  public getToken(): string | null {
    return this.cookieService.get(TOKEN_KEY);
    // return this.cookieService.get("token");
  }
  public saveRefreshToken(token: string): void {
    this.cookieService.delete('(REFRESHTOKEN_KEY');
    this.cookieService.set('(REFRESHTOKEN_KEY', token, 365);
  }
  public getRefreshToken(): string | null {
    return this.cookieService.get('(REFRESHTOKEN_KEY');
  }
  public saveUser(user: any): void {
    this.cookieService.delete(USER_KEY);
    this.cookieService.set('USER_KEY', JSON.stringify(user), 365);
  }
  public getUser(): any {
    const user = this.cookieService.get('USER_KEY');
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }
}
