import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class SharedGuard implements CanActivate {
  constructor(
    private router: Router,
    private readonly cookieService: CookieService,
    private tokenservice: TokenStorageService
  ) {}
  canActivate() {
    let roleValue = this.cookieService.get('rolename');
    if (
      (this.tokenservice.getToken() != '' &&
        this.tokenservice.getToken() != undefined &&
        this.tokenservice.getToken() != null &&
        roleValue.toLowerCase() === 'admin') ||
      roleValue.toLowerCase() === 'super user'
    ) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
