import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from './token.service';
import { map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root'
})
export class SearchRoleService {

  constructor(private http: HttpClient, private TokenStorage: TokenStorageService, private commonService: CommonService) { }


  searchRole(data: any) {
    return this.commonService.save(environment.endPointAPI + commonClass.Role + "/searchRole", data)
  }



}
