import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { Login } from '../interface/login';
import { CommonService } from './common.service';
import { commonClass } from '../helper/commonclass';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserloginService {
  constructor(private http: HttpClient, private commonService: CommonService) {}
  postUser(data: any, loginCount: any) {
    return this.commonService.wihtoutTokenSave(
      environment.endPointAPI +
        commonClass.Authenticate +
        '/login/' +
        loginCount,
      data
    );
  }
}
