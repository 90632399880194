import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserloginService } from '../../services/userlogin.service';
import { ForgetpasswordService } from 'src/app/services/forgetpassword.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from 'src/app/services/encryotion.service';
import { environment } from 'src/environments/environment';
import { UserSharingService } from 'src/app/services/user-sharing.service';
import { AppService } from 'src/app/services/AppService.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [UserSharingService],
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public ForgetPasswordForm!: FormGroup;

  public mainvalidation = true;
  count: any = 0;

  isLoading: boolean = false;
  submitted: boolean = false;
  forgetpasswordsubmitted: boolean = false;
  public localuser: any;
  displayStyle = 'none';
  rolevalue: any;
  loginCount: any;
  constructor(
    private toaster: ToastrService,
    private api: UserloginService,
    private forgetpasswordService: ForgetpasswordService,
    private formBuilder: FormBuilder,
    private router: Router,
    private readonly cookieService: CookieService,
    private encrypt: EncryptionService,
    private appService: AppService
  ) { }

  ngOnInit(): void {

    /* check for if token is already there then redirect to dashboard */
    let userLoginData = this.cookieService.get('token');
    let userRole = this.cookieService.get('rolename');
    if (userLoginData !== 'undefined' && userLoginData !== '') {
      // Check if its a employe (admin and superUser )
      if (userRole === 'Admin') {
        this.appService.setUserLoggedIn(true);
        this.router.navigate(['/ManageUser'], {});
      }
      if (userRole === 'Super User') {
        this.appService.setUserLoggedIn(true);
        this.router.navigate(['/ManageUser'], {});
      }
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      lsRememberMe: [false],
    });

    /* remember me username and password */
    if (
      this.cookieService.get('username') != '' &&
      this.cookieService.get('password') != ''
    ) {
      let decrypted = this.encrypt.decryptionAES(
        this.cookieService.get('password')
      );
      this.loginForm.patchValue({
        username: this.cookieService.get('username'),
        password: decrypted,
      });
    }

    /* get login count for wrong password */
    if (
      this.cookieService.get('logincount') !== '0' &&
      this.cookieService.get('logincount') !== null
    ) {
      this.count = this.cookieService.get('logincount');
    } else {
      this.count = 0;
      this.cookieService.set('logincount', this.count, 365);
    }

    this.ForgetPasswordForm = this.formBuilder.group({
      UserName: ['', Validators.required],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  get forgetPasswordcontrols() {
    return this.ForgetPasswordForm.controls;
  }

  /* Login button click event */
  btnlogin() {
    this.isLoading = true;
    this.submitted = true;
    /* Retrieve old usernamne for lock account */
    this.localuser = this.cookieService.get('oldusername');

    if (this.loginForm.controls['lsRememberMe'].value === false) {
      this.cookieService.set('password', '', 365);

      this.cookieService.set(
        'IsRememberMe',
        this.loginForm.controls['lsRememberMe'].value,
        365
      );
      this.cookieService.set(
        'oldusername',
        this.loginForm.controls['username'].value,
        365
      );
    } else {
      var username = this.loginForm.controls['username'].value;
      var password = this.loginForm.controls['password'].value;
      let encrypted = this.encrypt.encryptionAES(password);
      this.cookieService.set('password', encrypted, 365);
    }

    if (this.loginForm.valid) {
      let isLocked: Boolean = false;

      if (this.localuser !== null) {
        if (this.localuser !== this.loginForm.controls['username'].value) {
          this.count = 0;
          this.cookieService.set('logincount', this.count, 365);
        }
      }

      let payloadencryptpassword = this.encrypt.encryptUsingAES256(
        this.loginForm.controls['password'].value
      );

      /* 4 time wrong password enter account lock  */
      if (this.count > 3) {
        let payload1 = {
          Username: this.loginForm.value.username,
          Password: payloadencryptpassword,
          isLocked: false,
        };
        this.loginCount = this.count;

        this.api.postUser(payload1, this.loginCount).subscribe(
          (res: any) => {
            if (res.status === 401) {
              this.setlocalstoragecount(res);
              this.isLoading = false;
            } else if (res.token) {
              this.count = 0;

              this.cookieService.set('logincount', this.count, 365);
              var domainName = environment.domain;
              this.cookieService.set(
                'USER_KEY',
                JSON.stringify(res),
                365,
                '',
                domainName
              );
              this.cookieService.set('token', res.token, 365, '', domainName);
              this.cookieService.set('UserId', res.id, 365, '', domainName);
              this.cookieService.set(
                'username',
                this.loginForm.value.username,
                365,
                '',
                domainName
              );
              this.rolevalue = res.role[0];
              this.cookieService.set(
                'rolename',
                this.rolevalue,
                365,
                '',
                domainName
              );

              this.cookieService.set(
                'USER_KEY',
                JSON.stringify(res),
                365,
                '',
                environment.sixconsultingcorpdomain
              );
              this.cookieService.set('token', res.token, 365, '', environment.sixconsultingcorpdomain);
              this.cookieService.set('UserId', res.id, 365, '', environment.sixconsultingcorpdomain);
              this.cookieService.set(
                'username',
                this.loginForm.value.username,
                365,
                '',
                environment.sixconsultingcorpdomain
              );
              this.cookieService.set(
                'rolename',
                this.rolevalue,
                365,
                '',
                environment.sixconsultingcorpdomain
              );

              this.cookieService.set(
                'USER_KEY',
                JSON.stringify(res),
                365,
                '',
                environment.quotumtechdomain
              );
              this.cookieService.set('token', res.token, 365, '', environment.quotumtechdomain);
              this.cookieService.set('UserId', res.id, 365, '', environment.quotumtechdomain);
              this.cookieService.set(
                'username',
                this.loginForm.value.username,
                365,
                '',
                environment.quotumtechdomain
              );
              this.cookieService.set(
                'rolename',
                this.rolevalue,
                365,
                '',
                environment.quotumtechdomain
              );

              if (
                (res.role &&
                  res.role.length > 0 &&
                  res.role.includes('Admin')) ||
                res.role.includes('Super User')
              ) {
                this.cookieService.set(
                  'isFirstTimeLogin',
                  res.isFirstTimeLogin,
                  365
                );
                this.appService.setUserLoggedIn(true);
                if (!res.isFirstTimeLogin) {
                  this.router.navigate(['ManageUser']);
                } else {
                  this.router.navigate(['change_password']).then(() => {
                    window.location.reload();
                  });
                }
              } else {
                this.isLoading = false;
                this.submitted = false;
                this.mainvalidation = false;
                this.toaster.error(
                  'Only Admin and Super User can login to UMS.'
                );
              }

              return;
            } else if (res.message) {
              if (res.iscount) {
                this.setlocalstoragecount(res);
                let payload2 = {
                  Username: this.loginForm.value.username,
                  Password: payloadencryptpassword,
                  isLocked: true,
                  IsLockedByAdmin: false,
                };
                this.loginCount = this.count;

                this.api
                  .postUser(payload2, this.loginCount)
                  .subscribe((res1: any) => {
                    this.toaster.error(res1.message);
                    this.isLoading = false;
                  });
              } else {
                this.toaster.error(res.message);
                this.isLoading = false;
              }
            }
          },
          (err: any) => {
            this.mainvalidation = false;
            this.toaster.error(err.message);
            this.isLoading = false;
          }
        );
      } else {
        isLocked = false;

        let payload = {
          Username: this.loginForm.value.username,
          Password: payloadencryptpassword,
          isLocked: isLocked,
        };
        this.loginCount = this.count;

        this.api.postUser(payload, this.loginCount).subscribe(
          (res: any) => {
            if (res.status == 401) {
              // wrong Username and Password count.
              this.setlocalstoragecount(res);
            } else {
              if (res.message) {
                this.toaster.error(res.message);
                if (res.iscount) {
                  this.setlocalstoragecount(res);
                }
                this.isLoading = false;
              } else {
                this.count = 0;

                var domainName = environment.domain;
                this.cookieService.set(
                  'USER_KEY',
                  JSON.stringify(res),
                  365,
                  '',
                  domainName
                );
                this.cookieService.set('token', res.token, 365, '', domainName);
                this.cookieService.set('UserId', res.id, 365, '', domainName);
                this.cookieService.set(
                  'username',
                  this.loginForm.value.username,
                  365,
                  '',
                  domainName
                );
                this.rolevalue = res.role[0];
                this.cookieService.set(
                  'rolename',
                  this.rolevalue,
                  365,
                  '',
                  domainName
                );

                this.cookieService.set(
                  'USER_KEY',
                  JSON.stringify(res),
                  365,
                  '',
                  environment.sixconsultingcorpdomain
                );
                this.cookieService.set('token', res.token, 365, '', environment.sixconsultingcorpdomain);
                this.cookieService.set('UserId', res.id, 365, '', environment.sixconsultingcorpdomain);
                this.cookieService.set(
                  'username',
                  this.loginForm.value.username,
                  365,
                  '',
                  environment.sixconsultingcorpdomain
                );
                this.cookieService.set(
                  'rolename',
                  this.rolevalue,
                  365,
                  '',
                  environment.sixconsultingcorpdomain
                );

                this.cookieService.set(
                  'USER_KEY',
                  JSON.stringify(res),
                  365,
                  '',
                  environment.quotumtechdomain
                );
                this.cookieService.set('token', res.token, 365, '', environment.quotumtechdomain);
                this.cookieService.set('UserId', res.id, 365, '', environment.quotumtechdomain);
                this.cookieService.set(
                  'username',
                  this.loginForm.value.username,
                  365,
                  '',
                  environment.quotumtechdomain
                );
                this.cookieService.set(
                  'rolename',
                  this.rolevalue,
                  365,
                  '',
                  environment.quotumtechdomain
                );

                this.cookieService.set('logincount', this.count, 365);
                this.isLoading = false;

                if (
                  (res.role &&
                    res.role.length > 0 &&
                    res.role.includes('Admin')) ||
                  res.role.includes('Super User')
                ) {
                  this.cookieService.set(
                    'isFirstTimeLogin',
                    res.isFirstTimeLogin,
                    365
                  );
                  this.appService.setUserLoggedIn(true);
                  if (!res.isFirstTimeLogin) {
                    this.router.navigate(['ManageUser']);
                  } else {
                    this.router.navigate(['change_password']).then(() => {
                      window.location.reload();
                    });
                  }
                } else {
                  this.isLoading = false;
                  this.submitted = false;
                  this.mainvalidation = false;
                  this.toaster.error(
                    'Only Admin and Super User can login to UMS.'
                  );
                }
                return res;
              }
            }
          },
          (err: any) => {
            this.mainvalidation = false;
            this.isLoading = false;
            this.submitted = false;
            if (err.status == 401) {
              // wrong Username and Password count.
              this.setlocalstoragecount(err);
            } else if (err.status == 500) {
              this.isLoading = false;
              this.mainvalidation = false;
              this.submitted = false;
            }
          }
        );
      }
    } else {
      this.isLoading = false;
    }
  }

  /* common method for logincount store in cookies */
  setlocalstoragecount(e: any): any {
    this.isLoading = false;
    let localstoragecount: any = 0;

    localstoragecount = this.cookieService.get('logincount');
    this.count++;

    if (localstoragecount !== null) {
      if (localstoragecount === '0') {
        this.count = 0;
        this.cookieService.set('logincount', this.count, 365);
      }
      if (localstoragecount === this.count) {
        this.cookieService.set('logincount', this.count, 365);
      } else if (localstoragecount >= this.count) {
        this.count = localstoragecount;
        this.count++;
        this.cookieService.set('logincount', this.count, 365);
      } else if (localstoragecount < this.count) {
        this.cookieService.set('logincount', this.count, 365);
      }
    } else {
      this.cookieService.set('logincount', this.count, 365);
    }
    if (this.count < 3) {
      if (e.message === null) {
        this.toaster.error('UserName or Password is incorrect.');
      }
    }
    // this.serverError=true;
    this.isLoading = false;
  }

  /* RememberMe checkbox for username and password store in cookies */
  onCheckboxChange(e: any) {
    if (e.target.checked) {
      this.cookieService.set(
        'username',
        this.loginForm.controls['username'].value,
        365
      );
      this.cookieService.set(
        'password',
        this.loginForm.controls['password'].value,
        365
      );
      this.cookieService.set(
        'IsRememberMe',
        this.loginForm.controls['lsRememberMe'].value,
        365
      );
    } else {
      this.cookieService.set('username', '', 365);
      this.cookieService.set('password', '', 365);
      this.cookieService.set(
        'IsRememberMe',
        this.loginForm.controls['lsRememberMe'].value,
        365
      );
    }
  }

  /* forget password click event */
  btnforgetPassword() {
    this.forgetpasswordsubmitted = true;
    if (this.ForgetPasswordForm.valid) {
      this.isLoading = true;
      let payload = {
        UserName: this.ForgetPasswordForm.controls['UserName'].value,
        forgetPasswordFlag: true,
      };

      this.forgetpasswordService.forgetPassword(payload).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.status == 'Success') {
            this.ForgetPasswordForm.reset();
            this.displayStyle = 'none';
            this.toaster.success(res.message);
            this.forgetpasswordsubmitted = false;
          }
        },
        (err: any) => {
          this.isLoading = false;
          if (err && err.error && err.error.message) {
            this.toaster.error(err.error.message);
          } else {
            this.toaster.error('Please enter correct details.');
          }
        }
      );
    } else {
      this.ForgetPasswordForm.reset();
    }
  }

  /* forget password model open */
  openModal() {
    this.displayStyle = 'block';
  }

  /* forget password model close */
  close() {
    this.displayStyle = 'none';
    this.forgetpasswordsubmitted = false;
    this.ForgetPasswordForm.reset();
  }
}
