import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators'
import { TokenStorageService } from './token.service';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {
  isFirstTimeLogin = new BehaviorSubject<boolean>(false);

  constructor(private commonService: CommonService, private tokenService: TokenStorageService) { }
  // called from changePasswordCoponent,having changePassword data for api call
  postPassword(data: any) {
    return this.commonService.save(environment.endPointAPI + commonClass.Authenticate + "/change-password", data);
  }

  setisFirstTimeLogin(isFirstTimeLogin: any) {
    this.isFirstTimeLogin = isFirstTimeLogin;
  }
}
