import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Changeemail } from '../interface/changeemail';
import { TokenStorageService } from './token.service';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root',
})
export class ChangeemailService {
  constructor(
    private commonService: CommonService,
    private tokenService: TokenStorageService
  ) {}

  //called from changeEmail component with changeEmail form data for post in api
  changeEmail(data: any) {
    return this.commonService.save(
      environment.endPointAPI + commonClass.Authenticate + '/change-email',
      data
    );
  }
}
