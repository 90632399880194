import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Landing_pageComponent } from './components/landing-page/landing-page.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { SharedGuard } from './services/shared.guard';
import { LoginComponent } from './components/Login/Login.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'ManageUser',
    component: Landing_pageComponent,
    canActivate: [SharedGuard],
  },
  {
    path: 'change_password',
    component: ChangePasswordComponent,
    canActivate: [SharedGuard],
  },
  {
    path: 'change_email',
    component: ChangeEmailComponent,
    canActivate: [SharedGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
