import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root',
})
export class GetRoleService {
  constructor(private http: HttpClient, private commonService: CommonService) { }
  // called in mabagerole component -> for getting roles from api
  getRole(pageNo: any, pageSize: any) {
    return this.commonService.get(
      environment.endPointAPI +
      commonClass.Role +
      '/GetRoleWithPagination?pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize
    );
  }

  getroleswithtoken() {
    return this.commonService.get(
      environment.endPointAPI + commonClass.Role + '/GetRole'
    );
  }

  // Function for manager api call to display in edit user form
  getManagers() {
    return this.commonService.get(
      environment.endPointAPI + commonClass.Role + '/GetRole'
    );
  }
}
