import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { map, catchError, switchMap, timeout } from 'rxjs/operators';
import { Changeemail } from '../interface/changeemail';
import { TokenStorageService } from './token.service';
import { environment } from 'src/environments/environment';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  token: any = '';
  constructor(
    private http: HttpClient,
    private tokenService: TokenStorageService
  ) {
    this.token = this.tokenService.getToken();
  }

  get(url: string): any {
    return this.http
      .post(environment.endPointAPI + 'Authenticate/' + 'AuthenticateToken', {
        AccessToken: this.token,
      })
      .pipe(
        switchMap((res: any): any => {
          if (res.expired) {
            this.tokenService.saveToken(res.accessToken);
            this.token = this.tokenService.getToken();
            setTimeout(() => {}, 2000);
          }
          const httpOptions: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token,
          });
          return this.http
            .get(url, { headers: httpOptions, withCredentials: true })
            .pipe(
              map((res: any) => {
                return res;
              }),
              catchError((error: any) => {
                return throwError(error);
              })
            );
        })
      );
  }

  getbyId(url: string, id: any): any {
    return this.http
      .post(environment.endPointAPI + 'Authenticate/' + 'AuthenticateToken', {
        AccessToken: this.token,
      })
      .pipe(
        switchMap((res: any): any => {
          if (res.expired) {
            this.tokenService.saveToken(res.accessToken);
            this.token = this.tokenService.getToken();
            setTimeout(() => {}, 2000);
          }
          const httpOptions: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token,
          });
          return this.http
            .get(url + id, { headers: httpOptions, withCredentials: true })
            .pipe(
              map((res: any) => {
                return res;
              }),
              catchError((error: any) => {
                return throwError(error);
              })
            );
        })
      );
  }

  wihtoutTokenSave(url: string, data: any): Observable<any> {
    return this.http.post(url, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  save(url: string, data: any): any {
    return this.http
      .post(environment.endPointAPI + 'Authenticate/' + 'AuthenticateToken', {
        AccessToken: this.token,
      })
      .pipe(
        switchMap((res: any): any => {
          if (res.expired) {
            this.tokenService.saveToken(res.accessToken);
            this.token = this.tokenService.getToken();
            setTimeout(() => {}, 2000);
          }
          // const httpOptions: HttpHeaders = new HttpHeaders({
          //   'Content-Type': 'application/json',
          //   Authorization: 'Bearer ' + this.token,
          // });

          const headers = new HttpHeaders()
            .set('content-type', 'application/json')
            .set('Authorization', 'Bearer ' + this.token);

          return this.http
            .post(url, data, { headers: headers, withCredentials: true })
            .pipe(
              map((res: any) => {
                return res;
              }),
              catchError((error: any) => {
                return throwError(error);
              })
            );
        })
      );
  }

  update(url: string, data: any): any {
    return this.http
      .post(environment.endPointAPI + 'Authenticate/' + 'AuthenticateToken', {
        AccessToken: this.token,
      })
      .pipe(
        switchMap((res: any): any => {
          if (res.expired) {
            this.tokenService.saveToken(res.accessToken);
            this.token = this.tokenService.getToken();
            setTimeout(() => {}, 1000);
          }

          const httpOptions: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token,
          });
          return this.http
            .put(url, data, { headers: httpOptions, withCredentials: true })
            .pipe(
              map((res: any) => {
                return res;
              }),
              catchError((error: any) => {
                return throwError(error);
              })
            );
        })
      );
  }

  delete(url: string, id: any): any {
    return this.http
      .post(environment.endPointAPI + 'Authenticate/' + 'AuthenticateToken', {
        AccessToken: this.token,
      })
      .pipe(
        switchMap((res: any): any => {
          if (res.expired) {
            this.tokenService.saveToken(res.accessToken);
            this.token = this.tokenService.getToken();
            setTimeout(() => {}, 2000);
          }
          const httpOptions: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token,
          });
          return this.http
            .post(url + id, { headers: httpOptions, withCredentials: true })
            .pipe(
              map((res: any) => {
                return res;
              }),
              catchError((error: any) => {
                return throwError(error);
              })
            );
        })
      );
  }
}
