import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/services/token.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AppService } from './services/AppService.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'app';
  currentRoute = '';
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  setIsTimeout: boolean = false;
  constructor(
    private toaster: ToastrService,
    private tokenService: TokenStorageService,
    private router: Router,
    private idle: Idle, private keepalive: Keepalive,
    private appService: AppService
  ) {

    idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(900);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    // idle.onIdleEnd.subscribe(() => {
    //   this.idleState = 'No longer idle.'
    //   console.log(this.idleState);
    //   this.reset();
    // });

    this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
      console.log(userLoggedIn)
      if (!userLoggedIn) {
        //   idle.watch()
        //   this.timedOut = false;
        // } else {
        idle.stop();
      }
    })

    idle.onTimeout.subscribe(() => {
      if (this.currentRoute != '/login') {
        this.idleState = 'Timed out!';
        console.log("time out")
        this.timedOut = true;
        //  this.appService.getUserLoggedIn().subscribe((userLoggedIn: any) => {
        //  if (userLoggedIn) {
        this.toaster.warning(
          'Your session has been expired. Please login again.'
        );
        setTimeout(() => {
          this.tokenService.signOut();

          this.router.navigate(['/login']).then(() => {
            window.location.reload();
          });
        }, 3000);
      }
      else {
        idle.stop();
      }
      // })
    });
    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());



    this.reset();

    // this.setTimeout();
    // this.resetTimer();
    // this.userInactive.subscribe(() => {
    //   if (
    //     this.tokenService.getToken() != '' &&
    //     this.tokenService.getToken() != undefined &&
    //     this.tokenService.getToken() != null
    //   ) {
    //     this.toaster.warning(
    //       'Your session has been expired. Please login again.'
    //     );
    //     setTimeout(() => {
    //       this.tokenService.signOut();

    //       this.router.navigate(['/login']).then(() => {
    //         window.location.reload();
    //       });
    //     }, 3000);
    //   }
    // });
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  /* reset idle screen timer */
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('window:wheel')
  @HostListener('window:keypress')
  @HostListener('window:click')
  resetTimer() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  /* idle scree 15 min timer */
  setTimeout() {
    // this.userActivity = setTimeout(() => this.userInactive.next(undefined),
    //   900000);
    this.userActivity = setTimeout(() => this.userInactive.next(undefined),
      1800000);
  }

  @HostListener('window:mousemove')
  @HostListener('window:wheel')
  @HostListener('window:keypress')
  @HostListener('window:click')
  refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }
}
