import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenStorageService } from './token.service';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient, private tokenService: TokenStorageService, private commonService: CommonService) { }
  postUser(data: any) {
    return this.commonService.save(environment.endPointAPI + commonClass.Authenticate + "/register", data)
  }
  getUser() {
    return this.commonService.get(environment.endPointAPI + commonClass.Home + "/GetUser")
  }
}
