import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddRoleService } from '../../services/add-role.service';
import { GetRightsService } from '../../services/get-rights.service';
// import{ToastrService} from 'ngx-toastr'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss'],
})
export class AddRoleComponent implements OnInit {
  frmAddRole!: FormGroup;
  RightIdArray: any = [];
  addRoleLoader: boolean = false;
  isaddRole: boolean = false;
  denyString = '';
  capabilities = [
    { Id: '1', Name: 'Able to see only personal TS' },
    { Id: '2', Name: 'Enter hours' },
    { Id: '3', Name: 'Change Password' },
    { Id: '4', Name: 'Change Email' },
    { Id: '5', Name: 'Add Comments' },
    { Id: '6', Name: 'Upload Attachment' },
    { Id: '7', Name: 'Download Attachment' },
    { Id: '8', Name: 'Delete Attachment' },
    { Id: '9', Name: 'Add comments' },
    { Id: '10', Name: 'Able to see only Employee assigned to the Manager' },
    {
      Id: '11',
      Name: 'Able to run reports for the employees assigned to Manager',
    },
    { Id: '12', Name: 'Verify TS' },
    {
      Id: '13',
      Name: 'Shall be able to see all active Employees irrespective of Internal / External',
    },
    { Id: '14', Name: 'Shall be able to run reports All Employee data' },
    { Id: '15', Name: 'Unlock TS' },
  ];
  Rights: any;

  constructor(
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private addroleApi: AddRoleService,
    private getRightsApi: GetRightsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.frmAddRole = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(20),
          Validators.pattern('^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$'),
        ],
      ],
    });
    //function made in get rights service to get the all rights  here
    this.getRightsApi.getRights().subscribe((data: any) => {
      this.Rights = data;
    });
  }

  openDropdown() {
    $('.openDropMenu').slideToggle();
  }

  get f() {
    return this.frmAddRole.controls;
  }
  // function for first validating the add role textbox then passing role name,rights id to frmAddRole function in services.
  btnAddRole() {
    this.isaddRole = true;
    if (!this.frmAddRole.valid) {
      this.isaddRole = true;
    } else if (
      this.RightIdArray == '' &&
      this.frmAddRole.controls['name'].value != '' &&
      this.denyString == 'deny'
    ) {
      this.toaster.warning('Please grant At-least one capability.');
      this.denyString = '';
    } else if (
      this.RightIdArray == '' &&
      this.frmAddRole.controls['name'].value != ''
    ) {
      this.toaster.warning('Please select At-least one Capability.');
    } else if (this.frmAddRole.valid && this.RightIdArray.length > 0) {
      this.addRoleLoader = true;

      let payload = {
        name: this.frmAddRole.controls['name'].value,
        rightsId: this.RightIdArray,
      };
      this.addroleApi.addRole(payload).subscribe(
        (res: any) => {
          this.toaster.success(res.message);

          this.frmAddRole.patchValue({ name: '' });
          setTimeout(() => {
            this.router.navigate(['/RoleManagement']);
            this.addRoleLoader = false;
          }, 3000);

          return res;
        },
        (err: any) => {
          if (err && err.status == 500) {
            this.addRoleLoader = false;
            this.toaster.error(err.error.message);
          } else {
            this.addRoleLoader = false;
            this.toaster.error('Something went wrong.');
          }
        }
      );
    }
  }
  changeisaddRoleBoolean() {
    this.isaddRole = false;
  }
  // function for geting id of granted capabilities.
  getCapabilityValue(ID: any, string: any) {
    if (string == 'grant' && !this.RightIdArray.includes(ID)) {
      this.RightIdArray.push(ID);
    } else if (string == 'deny' && this.RightIdArray.includes(ID)) {
      const index = this.RightIdArray.indexOf(ID);

      if (index > -1) this.RightIdArray.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      this.RightIdArray = this.RightIdArray;
    }
    if (string == 'deny') {
      this.denyString = string;
    }
  }
}
