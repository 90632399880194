import { Injectable } from '@angular/core';
import * as CryptoTS from 'crypto-ts';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  key: string = "z!!!!!!!1sdfadsf56adf456asdfasdf";
  appProperties = {
    VALUES: {
      KEY: environment.CryptoTSkey,
      IV: environment.CryptoTSIV
    }
  }

  constructor() { }

  private key1 = CryptoJS.enc.Utf8.parse(environment.CryptoJSkey);
  private iv = CryptoJS.enc.Utf8.parse(environment.CryptoJSIV);

  // Methods for the encrypt and decrypt Using AES
  encryptUsingAES256(text: any): any {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key1, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
  decryptUsingAES256(decString: any) {
    var decrypted = CryptoJS.AES.decrypt(decString, this.key1, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }



  encryptionAES(msg: string | CryptoTS.ɵe) {
    // Encrypt
    const ciphertext = CryptoTS.AES.encrypt(msg, 'secret key 123');
    return ciphertext.toString();
  }

  decryptionAES(msg: string | CryptoTS.ɵf) {
    // Decrypt
    const bytes = CryptoTS.AES.decrypt(msg, 'secret key 123');
    const plaintext = bytes.toString(CryptoTS.enc.Utf8);
    return plaintext;
  }
}
