import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChangePasswordService } from '../../services/change-password.service';
import { Changepass } from 'src/app/interface/changePassword';
import { TokenStorageService } from 'src/app/services/token.service';
import { MustMatch } from 'src/app/helper/must-match.validator';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from 'src/app/services/encryotion.service';

@Component({
  selector: 'app-change_password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordObj!: Changepass;
  public frmchangePassword!: FormGroup;
  public localuserChangePassword: any;
  constructor(
    private toaster: ToastrService,
    private tokenStorage: TokenStorageService,
    private formbuilder: FormBuilder,
    private router: Router,
    private api: ChangePasswordService,
    private readonly cookieService: CookieService,
    private encrypt: EncryptionService
  ) { }
  samenew_pass = true;
  isloading: boolean = false;
  issubmitChangePassword: boolean = false;

  ngOnInit(): void {
    this.localuserChangePassword = this.cookieService.get('username');
    this.frmchangePassword = this.formbuilder.group(
      {
        currentPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?=.*?[A-Z] || (?=.*?[a-z]))(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$'
            ),
          ],
        ],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?=.*?[A-Z] || (?=.*?[a-z]))(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$'
            ),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?=.*?[A-Z] || (?=.*?[a-z]))(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$'
            ),
          ],
        ],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
  }

  get f() {
    return this.frmchangePassword.controls;
  }

  /* funtion for passing the password details to api function(postUser) */
  btnsubmitPassword() {
    this.issubmitChangePassword = true;
    if (
      this.frmchangePassword.controls['currentPassword'].value ===
      this.frmchangePassword.controls['newPassword'].value &&
      this.frmchangePassword.controls['currentPassword'].value !== '' &&
      this.frmchangePassword.controls['newPassword'].value !== ''
    ) {
      this.samenew_pass = false;
    } else {
      this.samenew_pass = true;
    }

    if (this.frmchangePassword.valid && this.samenew_pass) {
      this.isloading = true;

      let payload = {
        UserName: this.localuserChangePassword,
        NewPassword: this.encrypt.encryptUsingAES256(
          this.frmchangePassword.value.newPassword
        ),
        CurrentPassword: this.encrypt.encryptUsingAES256(
          this.frmchangePassword.value.currentPassword
        ),
      };
      this.api.postPassword(payload).subscribe(
        (res: any) => {
          this.isloading = false;
          if (res.status == 'Success') {
            this.toaster.success(res.message);
            setTimeout(() => {
              this.tokenStorage.signOut();
              this.router.navigate(['/login']).then(() => {
                window.location.reload();
              });
            }, 3000);
          }
          return res;
        },
        (err: any) => {
          this.isloading = false;
          if (err && err.error && err.error.message) {
            if (err.error.message == 'Incorrect password.') {
              this.toaster.error('Current Password is incorrect.');
            } else {
              this.toaster.error(err.error.message);
            }
          } else {
            this.toaster.error('Please enter correct details.');
          }
        }
      );
    }
  }

  /* new password blur event :- new password validation */
  newpasswordblur() {
    if (
      this.frmchangePassword.controls['currentPassword'].value ===
      this.frmchangePassword.controls['newPassword'].value &&
      this.frmchangePassword.controls['currentPassword'].value !== '' &&
      this.frmchangePassword.controls['newPassword'].value !== ''
    ) {
      this.samenew_pass = false;
    } else {
      this.samenew_pass = true;
    }
  }

  /* cancel button click redirct page */
  btncancel() {
    let isFirstTimeLogin: any;

    isFirstTimeLogin = this.cookieService.get('isFirstTimeLogin');

    if (isFirstTimeLogin === 'false') {
      this.router.navigate(['ManageUser']);
    } else {
      this.router.navigate(['login']).then(() => {
        window.location.reload();
      });
    }
  }
}
