import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenStorageService } from './token.service';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root',
})
export class EditRoleService {
  constructor(
    private http: HttpClient,
    private TokenStorage: TokenStorageService,
    private commonService: CommonService
  ) { }
  editRoleSubmit(Roleid: any, data: any, ) {
    return this.commonService.save(environment.endPointAPI + commonClass.Role + '/UpdateRole?id=' + Roleid, data)
  }
}
