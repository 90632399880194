<body>

    <ng-template
    [ngIf]="(currentRoute == '/login' ||  currentRoute == '/change_password' || currentRoute == '/change_email' ||   currentRoute=='/')">
    <router-outlet></router-outlet>
  </ng-template>

  <ng-template
  [ngIf]="!(currentRoute == '/login' ||  currentRoute == '/change_password' || currentRoute == '/change_email' ||  currentRoute=='/')">
  <app-header [currentvalue] ="currentRoute" ></app-header>
  <router-outlet></router-outlet>
  </ng-template>
</body>
