import { Injectable, OnInit, Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
@Injectable()
export class UserSharingService {
  private userStorage: any;
  private usernameStorage: any;
  constructor(private readonly cookieService: CookieService) {
    this.userStorage = this.cookieService.get('token');
    this.usernameStorage = this.cookieService.get('username');
  }

  postCrossDomainMessage(link: any, portal: any = 'cms') {
    let postURL: any;
    let iframeId: any;
    if (portal == 'cms') {
      postURL = environment.umsURL;

      iframeId = 'cms-ifr';
    }
    var iframe = document.getElementById(iframeId);
    if (iframe == null) return;
    var iWindow: any = (iframe as HTMLIFrameElement).contentWindow;
    const storageToken = this.userStorage;
    const storageUserName = this.usernameStorage;
    const storageData = {
      token: storageToken,
      username: storageUserName,
      singlesingon: true,
    };
    setTimeout(function () {
      iWindow.postMessage(storageData, environment.cmsURL);
    }, 1000);
  }
}
