<div class="md_header">
  <div class="head-logo"><a href="/ManageUser" ><img src="../assets/images/UMS_blue_LOGO.svg" alt="logo"></a></div>

  <div class="head_acts">
    <ul class="head_actlinks">
      <li>
        <p>Welcome {{localuser}}</p>
      </li>
      <li class="headact_link">
        <a title="Menu" (click)="menuOpen()" class="header_dropDown"><i class="fas fa-solid fa-bars"></i></a>
        <ul class="openMenu head_dropdown">
          <li><a routerLink="/change_password">Change Password</a></li>
          <li><a routerLink="/change_email">Change Email</a></li>

        </ul>
      </li>
      <li><a routerLink="/login" (click)="singOut()" title="Sign out"><i class="fas fa-power-off"></i></a></li>
    </ul>
  </div>
</div>

<div class="ae_crmtsec">
  <div class="inn_container">
    <div class="main_ttl">
      <h4>{{routename}}</h4>
    </div>
    <span
      *ngIf="(currentvalue)=='/Add-role' || (currentvalue)=='/EditRole' || (currentvalue)=='/FAQ' || (currentvalue)=='/RoleManagement' ">
      <ol class="breadcrumb">
        <li><a routerLink="/ManageUser">Home</a></li>
        <li class="active">{{routename}}</li>
      </ol>
    </span>



  </div>
</div>
