<div class="wrapper">

  <div class="ae_innbox">
    <div class="inn_container">
      <div class="umin_sec">


        <div class="umrt_box edrole_row">
          <label class="srch_mlbl">Role Name:<sup>*</sup></label>
          <div class="umrt_itm edrole_row">
            <div class="rlname" [ngStyle]="{'display': rolename_div_display }">{{Role_value}}</div>

            <div class="rlname" [ngStyle]="{'display': editrole_textBox_display}">
              <form [formGroup]="EditRole">
                <input type="text" name="user" id="'name" class="form-control" formControlName="editRoleText"
                  placeholder="Type Name" [(ngModel)]="input_val" value={{Role_value}}
                  (ngModelChange)="changeEditRoleBoolean()">
                <div *ngIf="editRoleOnSubmit && f['editRoleText'].errors " class="text-danger">

                  <span
                    *ngIf="((f['editRoleText'].errors['minlength'] || f['editRoleText'].errors['maxlength']) && (!f['editRoleText'].errors['pattern'])) ">Required
                    Minimum 4 character and Maximum 20.</span>
                  <div *ngIf="(f['editRoleText'].errors['pattern']) "> Special characters are not allowed.</div>


                  <span *ngIf="f['editRoleText'].errors['required'] ">Role name is required.</span>
                </div>


              </form>

            </div>
            <div class="eroll_linkbtn">
              <div class="edlink" [ngClass]="{'disableDiv': editButtonDisable}"><a (click)="openEditModel()"
                  id="eroleBtn"><i class="fas fa-solid fa-pen-to-square"></i>Edit</a></div>
              <div class="umrt_btn">
                <a class="def_btn bg_blue" (click)="update_roleValue()">Submit</a>
                <a class="def_btn bg_blue" routerLink="/RoleManagement">Cancel</a>
              </div>

            </div>
          </div>
        </div>


        <div class="ums_mbtbl umrtbl">
          <div class="tbl-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Capability</th>
                  <th>Grant</th>
                  <th>Deny</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let capability of Rights">
                  <td>{{capability.name}}</td>
                  <td><a (click)="getCapabilityValue(capability.id,'grant')" title="Grant"><input type="radio"
                        name={{capability.name}} value="Grant" [checked]="capability.checked"></a></td>
                  <td><a (click)="getCapabilityValue(capability.id,'deny')" title="Deny"><input type="radio"
                        name={{capability.name}} value="Deny" [checked]="!capability.checked"></a></td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<div id="eroleModal" class="srem_modbox" [ngStyle]="{'display':displayStyle}">
  <div class="sremod_content">
    <div class="sremod_header">
      <span (click)="closeEditModel()" class="close"
        onclick="document.getElementById('sremodal').style.display='none';">×</span>
      <h3>Edit Role Name</h3>
    </div>
    <div class="sremod_body">
      <form class="sremod_frm" action="">
        <div class="srfc_control ernapbox">
          <label class="srfc_lbl">Are you sure you want to edit Role Name?</label>
          <div class="umrt_btn mtop20">
            <a class="def_btn bg_blue" (click)="showTextBox()">YES</a>
            <a class="def_btn bg_blue" (click)="noChanges()">NO</a>
          </div>

        </div>


      </form>
    </div>
  </div>
</div>
<div>
  <app-loader *ngIf="isloading"></app-loader>
</div>

