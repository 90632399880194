import { Injectable } from '@angular/core';
import * as Excel from "exceljs";
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    EXCEL_EXTENSION = '.xlsx';

    constructor() {

    }


    public exportAsExcelFile(json: any[], excelFileName: string, headsing: any): void {

        var Heading = [headsing];
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        XLSX.utils.sheet_add_aoa(worksheet, Heading);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: this.EXCEL_TYPE
        });
        fs.saveAs(data, fileName + this.EXCEL_EXTENSION);
    }

    AdjustColumnWidth(worksheet: any, minimalWidth = 10) {
        worksheet.columns.forEach((column: any) => {
            let maxColumnLength = 0;
            column.eachCell({ includeEmpty: true }, (cell: any) => {
                maxColumnLength = Math.max(
                    maxColumnLength,
                    minimalWidth,
                    cell.value ? cell.value.toString().length : 0
                );
            });
            column.width = maxColumnLength + 10;
        });
    };
    exportExcel(data: any) {
        const workBook = new Excel.Workbook();
        const workSheet = workBook.addWorksheet('UserList');
        const excelData = [];
        var heading = ['First Name', 'Last Name', 'User Name', 'Email', 'Role', 'Status', 'Lock Status']
        const headerNames = Object.keys(data[0]);
        // workSheet.addRow(heading);
        workSheet.addRow(heading);
        workSheet.autoFilter = 'A1:G1';
        // Cell Style : Fill and Border
        this.AdjustColumnWidth(workSheet);
        data.forEach((item: any) => {

            // if (item.active) {
            //     item.active = "Active"
            // }
            // else if (!item.active) {
            //     item.active = "Inactive"
            // }

            // if (item.isLockedOut) {
            //     item.isLockedOut = "Yes"
            // }
            // else if (!item.isLockedOut) {
            //     item.isLockedOut = "No"
            // }
            const row = workSheet.addRow([item.firstName, item.lastName, item.userName, item.email, item.roles, item.active, item.isLockedOut]);
        });
        workBook.xlsx.writeBuffer().then(data => {
            let blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fs.saveAs(blob, 'UserList .xlsx');
        })
    }


    async generateExcel(datas: any) {


        // Excel Title, Header, Data

        const header = ['First Name', 'Last Name', 'User Name', 'Email', 'Company', 'Role', 'Status', 'Lock Status'];
        const data: any = datas;


        // Create workbook and worksheet
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('User Data');


        // Add Row and formatting

        // Add Header Row
        const headerRow = worksheet.addRow(header);

        headerRow.eachCell((cell, number) => {
            // cell.fill = {
            //   type: 'pattern',
            //   pattern: 'solid',
            //   fgColor: { argb: 'FFFFFF00' },
            // };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });

        // Add Data and Conditional Formatting
        data.forEach((d: any) => {
            const row = worksheet.addRow(d);
        }
        );

        worksheet.addRow([]);


        // Footer Row

        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'userDetails.xlsx');
        });

    }
}