import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root',
})
export class GetUsersService {
  constructor(private http: HttpClient, private commonService: CommonService) { }
  getUser(pageNo: any, pageSize: any, sortColumn: any, sortOrder: any) {
    return this.commonService.get(
      environment.endPointAPI +
      commonClass.Authenticate +
      '/get-users?pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize +
      '&sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder
    );
  }

  searchUser(data: any) {
    return this.commonService.save(
      environment.endPointAPI + commonClass.Authenticate + '/search-user',
      data
    );
  }

  downloadUser(data: any) {
    return this.commonService.save(
      environment.endPointAPI + commonClass.Authenticate + '/download-user',
      data
    );
  }
  // Function for manager api call to display in edit user form
  getManagers() {
    return this.commonService.get(
      environment.endPointAPI + commonClass.Authenticate + '/GetManager'
    );
  }
}
