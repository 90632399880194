import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { TokenStorageService } from './token.service';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { commonClass } from '../helper/commonclass';

@Injectable({
  providedIn: 'root'
})
export class UsersLockUnlockService {

  constructor(private http: HttpClient, private tokenService: TokenStorageService, private commonService: CommonService) { }

  userLocked(data: any) {
    return this.commonService.save(environment.endPointAPI + commonClass.Authenticate + "/lock-unlock-user", data)

  }
}
